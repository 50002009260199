import { createContext, useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import Axios from "axios";
import { BACKEND_URI } from "../Env";

import { EthereumContext } from "@providers/Ethereum";
import rinkebyContracts from "@networks/ethereum.test.4.rinkeby.json";
import mainnetContracts from "@networks/ethereum.mainnet.1.json";
import { OverrideNftSaleV2__factory } from "@contracts/index";
import { BigNumber, BytesLike, ContractTransaction, ethers } from "ethers";
import { Link } from "@components/Links";
import { CSSProperties } from "styled-components";

import { testResponse } from "./whitelist-response";

const saleContractAddress = mainnetContracts.saleContract.address;
const PENDING_TX = "PENDING_TX";
const NETWORK_ID_MISMATCH = "NETWORK_ID_MISMATCH";
const DEFAULT_WAVE_MAX = 4;
const WHITELIST_MERKLE_VERSION = "V19";

const DEFAULT_X = {
  Index: 0,
  Amount: "0",
  AmountNumber: 0,
  Proof: [],
};

export const SALE_PRICE = BigNumber.from("0x011c37937e080000"); //0.8 eth in hex
export const DISCOUNT_PRICE = BigNumber.from("0xf8b0a10e470000"); //0.7 eth in hex
export const PREMIUM_DISCOUNT_PRICE = BigNumber.from("0xd529ae9e860000"); //0.6 eth in hex

console.error(ethers.utils.formatEther(PREMIUM_DISCOUNT_PRICE));

const REQUIRED_BLOCK_CONFIRMATIONS = 1;
const toastLinkStyle: CSSProperties = {
  textDecoration: "underline",
  color: "#1387f5",
};

const makeEtherScanLink = (txHash: string, text: string): JSX.Element => {
  const txUri = `https://etherscan.io/tx/${txHash}`;
  return (
    <Link style={toastLinkStyle} href={txUri} target="_blank">
      {text}
    </Link>
  );
};

export enum OverrideSaleState {
  PRE_WL_SALE = "PRE_WL_SALE",
  WL_SALE = "WL_SALE",
  PRE_PUBLIC_SALE = "PRE_PUBLIC_SALE",
  PUBLIC_SALE = "PUBLIC_SALE",
  POST_PUBLIC_SALE = "POST_PUBLIC_SALE",
}

export enum WhitelistType {
  FREE = "FREE",
  DISCOUNT = "DISCOUNT",
  PREMIUM_DISCOUNT = "PREMIUM DISCOUNT",
}

interface IWlQuantityEligible {
  free: number;
  premiumDiscount: number;
  discount: number;
  max: number;
  merkleData: IMerkleDataResponse;
  eligible: IWhitelistEntry[];
  minted: IWhitelistEntry[];
}

interface IMerkleDataResponse {
  success: boolean;
  message?: string;
  FREE: IMerkleEntry;
  PREMIUM_DISCOUNT: IMerkleEntry;
  DISCOUNT: IMerkleEntry;
}

interface IOverrideNftSaleV2Context {
  saleState: OverrideSaleState;
  wlQuantityEligible: IWlQuantityEligible | null;
  maxPerTx: number;
  saleMint: (quantity: number) => Promise<void>;
  whitelistMint: (quantity: number) => Promise<void>;
  getWhitelists: () => Promise<any>;
  totalSupply:number;
}

export const OverrideNftSaleV2Context =
  createContext<IOverrideNftSaleV2Context>({
    saleState: OverrideSaleState.WL_SALE,
    wlQuantityEligible: null,
    maxPerTx: 10,
    saleMint: (quantity: number) => new Promise<void>((resolve) => resolve()),
    whitelistMint: (quantity: number) =>
      new Promise<void>((resolve) => resolve()),
    getWhitelists: async () => [],
    totalSupply: 970
  });

interface IV2BackendStatus {
  saleContractAddress: string;
}

interface IMerkleEntry {
  Index: number;
  Amount: string;
  AmountNumber: number;
  Proof: string[];
}

interface IWhitelistEntry {
  wlType: WhitelistType;
  price: string;
  icon: string;
}

const getMerkleData = async (
  version: string,
  ethAddress: string
): Promise<IMerkleDataResponse> => {
  const uri =
    BACKEND_URI + `/api/whitelist/merkle/${version}/address/${ethAddress}`;
  console.log(`GET ${uri}`);

  // @ts-ignore
  return await Axios.get<IMerkleDataResponse>(uri)
    .then(async (response) => {
      // const dataStr = JSON.stringify(response.data, null, '\t');

      if (!response.data || !response.data.success) {
        throw new Error(`Request Failed: ${uri}\n ${response.data?.message}`);
      }
      // console.log(`GET ${uri} Response\n${dataStr}`);

      return response.data;
    })
    .catch(async (err) => {
      console.error(err);
      throw err;
      // return testResponse;
    });
};

const parseWlData = (
  merkleData: any,
  freeMinted: any,
  premiumDiscountMinted: any,
  discountMinted: any
): IWlQuantityEligible => {
  const freeMintQuantity = Math.max(
    merkleData.FREE.AmountNumber - freeMinted,
    0
  );
  const premiumDiscountQuantity = Math.max(
    merkleData.PREMIUM_DISCOUNT.AmountNumber - premiumDiscountMinted,
    0
  );
  const discountQuantity = Math.max(
    merkleData.DISCOUNT.AmountNumber - discountMinted,
    0
  );

  const eligible = new Array<IWhitelistEntry>();
  const minted = new Array<IWhitelistEntry>();

  for (let i = 1; i <= merkleData.FREE.AmountNumber; i++) {
    (i <= freeMintQuantity ? eligible : minted).push({
      wlType: WhitelistType.FREE,
      price: i <= freeMintQuantity ? "FREE" : "MINTED",
      icon: "images/wl-free.png",
    });
  }

  for (let i = 1; i <= merkleData.PREMIUM_DISCOUNT.AmountNumber; i++) {
    (i <= premiumDiscountQuantity ? eligible : minted).push({
      wlType: WhitelistType.PREMIUM_DISCOUNT,
      price: i <= premiumDiscountQuantity ? "Ξ 0.06" : "MINTED",
      icon: "images/wl-premium.png",
    });
  }

  for (let i = 1; i <= merkleData.DISCOUNT.AmountNumber; i++) {
    (i <= discountQuantity ? eligible : minted).push({
      wlType: WhitelistType.DISCOUNT,
      price: i <= discountQuantity ? "Ξ 0.07" : "MINTED",
      icon: "images/wl-standard.png",
    });
  }

  return {
    free: freeMintQuantity,
    premiumDiscount: premiumDiscountQuantity,
    discount: discountQuantity,
    max: freeMintQuantity + premiumDiscountQuantity + discountQuantity,
    merkleData: merkleData,
    eligible: eligible,
    minted,
  };
};

const OverrideNftSaleV2Provider = ({ children }: any) => {
  const { account, provider } = useContext(EthereumContext);
  const [wlVersion] = useState(WHITELIST_MERKLE_VERSION);
  const [tx, setTx] = useState<ContractTransaction>();
  const [wlQuantityEligible, setWlQuantityEligible] =
    useState<IWlQuantityEligible | null>(null);
  const [maxPerTx] = useState(10);
  const [saleState] = useState<OverrideSaleState>(OverrideSaleState.WL_SALE);
  const [totalSupply, setTotalSupply] = useState(970);
  

  const updateWlQuantityEligible = async (
    ethAddress: string
  ): Promise<IWlQuantityEligible | null> => {
    try {
      const merkleData = await getMerkleData(wlVersion, ethAddress);

      if (!provider) {
        console.warn("No eth provider");
        toast.warn("No eth provider");
        return null;
      }

      const conn = await OverrideNftSaleV2__factory.connect(
        saleContractAddress,
        provider
      );

      const freeMinted = (await conn.freeBalance(ethAddress)).toNumber();
      const premiumDiscountMinted = (
        await conn.premiumDiscountBalance(ethAddress)
      ).toNumber();
      const discountMinted = (
        await conn.discountBalance(ethAddress)
      ).toNumber();

      const parsedData = parseWlData(
        merkleData,
        freeMinted,
        premiumDiscountMinted,
        discountMinted
      );
      setWlQuantityEligible(parsedData);
      return parsedData;
    } catch (err) {
      console.error(err);
      // const testData = parseWlData(testResponse, 1, 0, 0);
      setWlQuantityEligible(null);
      // setWlQuantityEligible(testData);
    }

    return null;
  };

  useEffect(() => {
    if (account) {
      updateWlQuantityEligible(account.address);
    } else {
      setWlQuantityEligible(null);
    }
  }, [account]);

  const saleMint = async (quantity: number): Promise<void> => {
    if (tx) {
      console.error("Transaction already in process");
      toast.error("Transaction already in process");
      return;
    }

    if (account) {
      try {
        const totalEth: BigNumber = SALE_PRICE.mul(quantity);
        const tx = await OverrideNftSaleV2__factory.connect(
          saleContractAddress,
          account.signer
        ).saleMint(quantity, {
          value: totalEth,
        });
        setTx(tx);
        toast.loading(
          makeEtherScanLink(tx.hash, `Mint Tx: ${tx.hash.substr(0, 8)}`),
          {
            toastId: PENDING_TX,
            autoClose: false,
          }
        );

        await tx.wait(REQUIRED_BLOCK_CONFIRMATIONS);
        toast.dismiss(PENDING_TX);
        toast.success(makeEtherScanLink(tx.hash, "Mint Tx Complete!"), {
          autoClose: 30000
        });
        // console.log('mint:initial confirmation #' + txComplete.confirmations);

        // const txConfirmed = await tx.wait(10);
        // console.log("mint:final confirmation #" + txComplete.confirmations);
      } catch (err: any) {
        toast.dismiss(PENDING_TX);
        console.error(err.message);
        toast.error(err.message);
      } finally {
        setTx(undefined);
      }
    } else {
      console.error("No account defined.");
      toast.error("No account defined.");
    }
  };

  const whitelistMint = async (quantity: number): Promise<void> => {
    if (tx) {
      console.error("Transaction already in process");
      toast.error("Transaction already in process");
      return;
    }

    if (quantity <= 0) {
      console.error("quantity should be > 0");
      toast.error("quantity should be > 0");
      return;
    }

    if (!account) {
      console.error("No account defined.");
      toast.error("No account defined.");
      return;
    }

    try {
      const wlqe = await updateWlQuantityEligible(account.address);

      if (!wlqe) {
        console.error("Wl Quantity Eligible undefined");
        toast.error("Wl Quantity Eligible undefined");
        return;
      }

      if (wlqe.max <= 0) {
        console.error("totalWhitelist should be > 0");
        toast.error("totalWhitelist should be > 0");
        return;
      }

      const freeMintQuantity = Math.min(quantity, wlqe.free);
      const premiumDiscountQuantity = Math.min(
        quantity - freeMintQuantity,
        wlqe.premiumDiscount
      );
      const discountQuantity = Math.min(
        quantity - freeMintQuantity - premiumDiscountQuantity,
        wlqe.discount
      );

      const indexesParam: [BigNumber, BigNumber, BigNumber] = [
        BigNumber.from(wlqe.merkleData.FREE.Index),
        BigNumber.from(wlqe.merkleData.PREMIUM_DISCOUNT.Index),
        BigNumber.from(wlqe.merkleData.DISCOUNT.Index),
      ];

      const amountsParam: [BigNumber, BigNumber, BigNumber] = [
        BigNumber.from(wlqe.merkleData.FREE.Amount),
        BigNumber.from(wlqe.merkleData.PREMIUM_DISCOUNT.Amount),
        BigNumber.from(wlqe.merkleData.DISCOUNT.Amount),
      ];

      const amountsToBuyParam: [BigNumber, BigNumber, BigNumber] = [
        BigNumber.from(freeMintQuantity),
        BigNumber.from(premiumDiscountQuantity),
        BigNumber.from(discountQuantity),
      ];

      const proofsParam: [BytesLike[], BytesLike[], BytesLike[]] = [
        wlqe.merkleData.FREE.Proof,
        wlqe.merkleData.PREMIUM_DISCOUNT.Proof,
        wlqe.merkleData.DISCOUNT.Proof,
      ];

      const priceParam: BigNumber = BigNumber.from(premiumDiscountQuantity)
        .mul(PREMIUM_DISCOUNT_PRICE)
        .add(BigNumber.from(discountQuantity).mul(DISCOUNT_PRICE));
      console.warn(`compbowPrice: ${ethers.utils.formatEther(priceParam)}`);
      console.warn(`saleContractAddress: ${saleContractAddress}`);

      const tx = await OverrideNftSaleV2__factory.connect(
        saleContractAddress,
        account.signer
      ).whitelistMint(
        amountsToBuyParam,
        amountsParam,
        indexesParam,
        proofsParam,
        {
          value: priceParam,
        }
      );
      setTx(tx);
      toast.loading(
        makeEtherScanLink(tx.hash, `Mint Tx: ${tx.hash.substr(0, 8)}`),
        {
          toastId: PENDING_TX,
          autoClose: false,
        }
      );

      await tx.wait(REQUIRED_BLOCK_CONFIRMATIONS);
      toast.dismiss(PENDING_TX);
      toast.success(makeEtherScanLink(tx.hash, "Mint Tx Complete!"), {
        autoClose: 30000
      });

      await updateWlQuantityEligible(account.address);
      // console.log('mint:initial confirmation #' + txComplete.confirmations);

      // const txConfirmed = await tx.wait(10);
      // console.log("mint:final confirmation #" + txComplete.confirmations);
    } catch (err: any) {
      toast.dismiss(PENDING_TX);
      console.error(err.message);
      toast.error(err.message);
    } finally {
      setTx(undefined);
    }
  };

  const getWhitelists = async () => {
    if (account) {
      return getMerkleData(WHITELIST_MERKLE_VERSION, account.address);
    }
    return [];
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      if (!provider) {
        return;
      }
      try {
        const conn = await OverrideNftSaleV2__factory.connect(
          saleContractAddress,
          provider
        );
        const ts = (await conn.totalSupply()).toNumber();
        console.log(ts);
        setTotalSupply(ts);
      } catch (err) {
        console.error(err);
      }
    }, 4000);
    return () => clearInterval(interval);
  });

  return (
    <OverrideNftSaleV2Context.Provider
      value={{
        saleState: saleState,
        wlQuantityEligible: wlQuantityEligible,
        maxPerTx: maxPerTx,
        saleMint: saleMint,
        whitelistMint: whitelistMint,
        getWhitelists: getWhitelists,
        totalSupply: totalSupply
      }}
    >
      {children}
    </OverrideNftSaleV2Context.Provider>
  );
};
export default OverrideNftSaleV2Provider;
