// @ts-nocheck

import React from 'react';
import anime from 'animejs';
import {
  AnimatorRef,
  AnimatorClassSettings,
  withAnimator,
} from '@arwes/animation';

import { Card } from './Cards';
import { AnimatedCardContainer, OverlayedSVG, StyledPath } from './styled';

type AnimatedCardProps = {
  children: React.ReactNode;
};
export const AnimatedCard = ({ children }: AnimatedCardProps) => {
  return (
    <AnimatedCardContainer>
      <>
        <OverlayedSVG
          viewBox='0 0 100 40'
          preserveAspectRatio='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          {/* left-top M0,20 L0,0 L50,0 */}
          <AnimatedCorner d='M0,20 L0,0 L50,0' animator={{ activate: true }} />

          {/* right-top M50,0 L100,0 L100,20 */}
          <AnimatedCorner
            d='M50,0 L100,0 L100,20'
            animator={{ activate: true }}
          />
          {/* left-bottom M50,40 L0,40 L0,20 */}
          <AnimatedCorner
            d='M50,40 L0,40 L0,20'
            animator={{ activate: true }}
          />
          {/* right-bottom M100,20 L100,40 L50,40 */}
          <AnimatedCorner
            d='M100,20 L100,40 L50,40'
            animator={{ activate: true }}
          />
        </OverlayedSVG>
        <Card>{children}</Card>
      </>
    </AnimatedCardContainer>
  );
};

const Corner = (props: any) => {
  const ref = React.useRef(null);
  props.animator.setupAnimateRefs(ref);

  return <StyledPath ref={ref} {...props} />;
};

const duration = { enter: 500, exit: 500 };
const onAnimateEntering = (animator: AnimatorRef, ref: any): void => {
  anime({
    targets: ref.current,
    duration: animator.duration.enter,
    easing: 'linear',
    strokeDashoffset: [-1200, 0],
  });
};
const onAnimateExiting = (animator: AnimatorRef, ref: any): void => {
  anime({
    targets: ref.current,
    duration: animator.duration.enter,
    easing: 'linear',
    strokeDashoffset: [0, -1200],
  });
};
const animatorClassSettings: AnimatorClassSettings = {
  duration,
  onAnimateEntering,
  onAnimateExiting,
};

// @ts-ignore
export const AnimatedCorner = withAnimator(animatorClassSettings)(Corner);
