import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {    
    background: #000;
    margin: 0;
    font-size: ${({ theme }) => theme.fontSizes.xsmall}; 
    color: ${({ theme }) => theme.colors.bodyText};
    font-family: 'Jost', sans-serif;

    @media ${({ theme }) => theme.breakpoints.sm} {
      font-size: ${({ theme }) => theme.fontSizes.small};
    }

    @media ${({ theme }) => theme.breakpoints.md} {
      font-size: ${({ theme }) => theme.fontSizes.normal};
    }
  }

  * {
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
    overflow: scroll;
    overflow-x: hidden;
  } 

  h1 {
    color: ${({ theme }) => theme.colors.accent};
    font-family: 'NeonVampire', sans-serif;
    letter-spacing: .025em;
    font-size: 2.75em;
    font-weight: 100;
  }

  h2 {
    font-family: 'RoadRage', sans-serif;
    letter-spacing: .025em;
    font-size: 1.75em;
    font-weight: 100;
    color: ${({ theme }) => theme.colors.accentSecondary};
    line-height: 1em;
    text-shadow: 4px 4px #111;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    h2 {
      font-size: 2.75em;
    }
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    h1 {
      font-size: 5em;
    }
  }

  h1, h2, h3, ul {
    margin-bottom: 0;
    margin-top: 0;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.accentSecondary};
  }
  a:hover {
    color: ${({ theme }) => theme.colors.accent};
  }

  p {
    margin-top: 0;
  }

  ul {
    padding: 0 16px;
  }

  mark {
    font-weight: bolder;
    color: white;
    margin: 0 -0.4em;
    padding: 0.1em 0.4em;
    background: transparent;
    background-image: linear-gradient(
      to right,
      rgba(255, 0, 180, 0.25),
      rgba(0, 227, 254, 0.25)
    );
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  .darkBg {
    background: rgba(0, 0, 0, 0.5);
  }

  .opaqueBg {
    background: rgba(0, 0, 0, 1);
  }

  .spread {
    @media ${({ theme }) => theme.breakpoints.md} {
      margin-bottom: 64px;
    }
  }

  .centered {
    text-align: center;
    width: 100%;
  }

  .lgInline {
    @media ${({ theme }) => theme.breakpoints.lg} {
      display: inline;
    }
  }
`;

export default GlobalStyle;
