import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import defaultTheme from '@theme/theme';
import App from './App';
import GlobalStyle from './GlobalStyles';
import ConfigProvider from './ConfigContext';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider theme={defaultTheme}>
        <GlobalStyle />
        <ConfigProvider>
          <App />
        </ConfigProvider>
      </ThemeProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
