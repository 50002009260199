import { ToastContainer } from 'react-toastify';
import { useRoutes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import { ContentContainer } from '@components/Containers';
import OverrideNftSaleV2Provider from '@src/providers/OverrideNftSaleV2Provider';
import EthereumContextProvider from '@providers/Ethereum';
import NavBar from '@widgets/NavBar';

import { useContext } from 'react';
import { ConfigContext } from './ConfigContext';
import getRoutes from './routes';
import BoomboxProvider from './providers/BoomboxProvider';

function App() {
  const { pageWhitelist, pageMint, pageDebug } = useContext(ConfigContext);
  let element = useRoutes(getRoutes(pageWhitelist, pageMint, pageDebug));

  return (
    <div className="App">
      <ToastContainer position="bottom-left" />
      <EthereumContextProvider>
        <OverrideNftSaleV2Provider>
          <BoomboxProvider>
            <NavBar />
            <ContentContainer>{element}</ContentContainer>
          </BoomboxProvider>
        </OverrideNftSaleV2Provider>
      </EthereumContextProvider>
    </div>
  );
}

export default App;
