import { keyframes } from 'styled-components';

export const fadeInOpacity = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const fadeOutOpacity = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
`;

export const slideIn = keyframes`
  0% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(0);
  }
`;

export const slideOut = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50px);
  }
`;

export const glitchIn = keyframes`
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const glitchOut = keyframes`
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;
