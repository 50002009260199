import styled from "styled-components";
import { SVGButton } from "@components/Buttons";
// import { size as breakpointSize } from '@theme/breakpoints';
// import { SectionProps } from './types';

export const StyledSVGButton = styled(SVGButton)`
  height: 30px;
  width: 30px;
`;

export const StyledIncrementer = styled.div`
  background-color: ${({ theme }) => theme.colors.gray90};
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  * {
    color: black;
  }
`;

export const StyledInput = styled.input`
  background-color: transparent;
  border: 0;
  outline: 0;
  text-align: center;
  color: white;
  width: 50px;
  font-size: 36px;
  font-family: "NeonOverdrive", sans-serif;
`;
