import colors from './colors';
import { breakpoints, maxBreakpoints } from './breakpoints';

const theme = {
  fontWeights: {
    normal: 400,
  },
  fontSizes: {
    xsmall: '12px',
    small: '16px',
    normal: '18px',
    large: '32px',
    headline: '10vh',
    title: '1.5vh',
    subheading_1: '1vh',
    subheading_2: '1vh',
  },
  spacing: {
    xs: '2px',
    sm: '4px',
    md: '8px',
    lg: '16px',
    xl: '32px',
    xxl: '64px',
  },
  radii: {
    sm: '0.125rem',
    md: '0.25rem',
    lg: '0.5rem',
    xl: '0.75rem',
    full: '9999px',
  },
  colors,
  sizes: {
    navbarHeight: '24px',
  },
  breakpoints,
  maxBreakpoints,
};

export default theme;
