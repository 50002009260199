// @ts-nocheck
import { useContext, useState, useEffect } from "react";
import {
  MintContainer,
  MintButton,
  FabricantContainer,
  Fabricants,
  MintMainContainer,
  FabricanBannerText,
  MintLeftContainer,
  MintRightContainer,
  MintContentContainer,
  ConnectButton,
  AccentedHeaderSmaller
} from "./styled";
import { AccentedHeader } from "@src/components/Typography";
import { AnimatedCard } from '@components/Cards';
import { OverrideNftSaleV2Context } from "@providers/OverrideNftSaleV2Provider";
import { ConnectionStatus, EthereumContext } from "@src/providers/Ethereum";
import { Link } from "@components/Links";
import { toast } from "react-toastify";

import Incrementer from "@components/Incrementer";
import ConnectFeatureBar from "@widgets/ConnectFeatureBar";

import { CardContainer } from "@src/components/Containers";

interface IProps {
  className?: string;
}

// const getMetadata = async (
//   tokenId: number
// ): Promise<IMetadata> => {

//   const metadataUri = BACKEND_URI + `/api/metadata/override/tokens/${tokenId}`;
//   console.log(`GET ${metadataUri}`);

//   return await Axios.get<IMetadata>(metadataUri)
//     .then(async (response) => {
//       const dataStr = JSON.stringify(response.data, null, '\t');
//       if (!response.data) {
//         throw new Error(`Request Failed: ${metadataUri}`);
//       }
//       return response.data;
//     })
//     .catch(async (err) => {
//       console.error(err);
//       throw err;
//     });
// };

interface IFabricant {
  name:string;
  id:number;
  img:string;
}

const Mint = ({ className, ...props }: IProps) => {
  const GET_METAMASK = "GET_METAMASK";
  const CONNECTING = "CONNECTING";

  const { status, connect, provider, account } = useContext(EthereumContext);
  const { maxPerTx, saleMint, totalSupply } = useContext(OverrideNftSaleV2Context);

  const tx = undefined;
  const [quantity, setQuantity] = useState<number>(1);
  const [min, setMin] = useState<number>(1);
  const [max, setMax] = useState<number>(10);
  const [fabricants, setFabricants] = useState<IFabricant[]>([]);
  const [fabricantEls, setFabricantEls] = useState(<></>);

  useEffect(() => {
    const els = fabricants.map((fab) => {
        return <><FabricantContainer>
          <img src={fab.img} alt={`${fab.name}`} />
          <FabricanBannerText><mark>{fab.name}</mark></FabricanBannerText>
        </FabricantContainer></>
    });
    setFabricantEls(els);
  }, [fabricants]);

  useEffect(() => {
    const interval = setInterval(() => {
      const fabs = new Array<IFabricant>();
      for(let i = 0; i < 8; i++) {
        const tokenId = Math.min(Math.max(Math.round(1 + Math.random() * (totalSupply - 1)), 1), totalSupply);
        fabs.push({
          name: `Fabricant #${tokenId}`,
          id: tokenId,
          img: `http://cdn.neonplexus.io/collections/neon-plexus-override/terminal/images-250x275/${tokenId}.png`,
        });
      }
      setFabricants(fabs);
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  const onClickMint = async () => {
    (window as any).gtag('event', 'conversion', {
      'send_to': 'AW-10827763561/CzztCKPFz7kDEOmeiqso'
    });
    saleMint(quantity);
  }

  const onClickConnect = async () => {
    (window as any).gtag('event', 'conversion', {
      'send_to': 'AW-10827763561/F_H1CPWbz7kDEOmeiqso'
    });

    if (!provider) {
      toast.error(
        <>
          No ETH network provider found!{" "}
          <Link
            style={toastLinkStyle}
            href="https://metamask.io/"
            target="_blank"
          >
            Get Metamask
          </Link>
        </>,
        {
          toastId: GET_METAMASK,
          autoClose: false,
          closeButton: true,
        }
      );
      return;
    }

    if (status !== ConnectionStatus.CONNECTING) {
      try {
        toast.loading("Connecting ...", {
          toastId: CONNECTING,
          autoClose: false,
        });
        await connect();
        toast.dismiss(CONNECTING);
      } catch (connectionStatus: any) {
        toast.dismiss(CONNECTING);
        if ("message" in connectionStatus) {
          // 4001 - user rejected the request
          toast.error(connectionStatus.message);
        } else {
          toast.error(`Failed to connect: ${connectionStatus}`);
        }
      }
    }
  };

  return (
    <MintMainContainer>
      <CardContainer direction='horizontal' className="darkBg">
        <AnimatedCard>
          <AccentedHeaderSmaller className='lgInline'>
            MINT<span>LIVE</span>
          </AccentedHeaderSmaller>
          <span>CREATORS FIRST. EMPOWER COMMUNITY. PREVENT DYSTOPIA.</span>
          <br />
          <p>
            <mark>Started April 25th</mark>
          </p>
        </AnimatedCard>
      </CardContainer>
      <MintContentContainer >
        <MintRightContainer direction='horizontal' className='darkBg'>
          <AnimatedCard>
            <video
              poster="https://cdn.neonplexus.io/assets/neonplexus-io/mint/prereveal_preview.png"
              tabIndex={0}
              preload="preload"
              style={{ width: "100%", "max-height": "550px", marigin: "0", padding: "0", objectFit: "cover" }}
              autoPlay
              loop
              muted
              playsInline
            >
              <source
                type="video/webm"
                src="https://cdn.neonplexus.io/assets/neonplexus-io/mint/prereveal_loop.webm"
              />
              <source
                type="video/mp4"
                src="https://cdn.neonplexus.io/assets/neonplexus-io/mint/prereveal_loop.mov"
              />
            </video>
            {status === ConnectionStatus.CONNECTED &&
              <MintContainer>
              <Incrementer
                quantity={quantity}
                min={min}
                max={max}
                onChange={setQuantity}
              />
              <MintButton
                disabled={quantity === 0 || tx !== undefined}
                onClick={onClickMint}
              >
                <div>
                  <span>Mint</span>
                </div>
                <div className="mega">{">>"}</div>
              </MintButton>
            </MintContainer>
            }
            {status !== ConnectionStatus.CONNECTED &&
              <MintContainer>
              <ConnectButton
                onClick={onClickConnect}
              >
                <div>
                  <span>Connect</span>
                  <br />
                  <span>Wallet</span>
                </div>
                <div className="mega">{">>"}</div>
              </ConnectButton>
            </MintContainer>
            }
            
          </AnimatedCard>
        </MintRightContainer>
        <MintLeftContainer direction="vertical" className='darkBg'>
          <AnimatedCard>
            <p className='centered'>
              <mark>
                {totalSupply} of 9000 Fabricants on{' '}
                <a href='https://opensea.io/collection/neonplexusoverride' target="_blank">OpenSea!</a>
              </mark>
            </p>
            <Fabricants>
              {fabricantEls}
            </Fabricants>
          </AnimatedCard>
        </MintLeftContainer>
      </MintContentContainer>

      {/* <CardContainer direction="horizontal" className="darkBg spread">
            <AnimatedIntroCard>
              <InfoUl>
                <li>
                  Public Price: <MintDateSpan>0.08 ETH</MintDateSpan>
                </li>
                <li>
                  Marketplace:<Link href='https://opensea.io/collection/neonplexusoverride'>OpenSea</Link>
                </li>
              </InfoUl>

              <Countdown date={new Date(1650902400 * 1000)}>
                  <MintContainer>
                <Incrementer
                  quantity={quantity}
                  min={min}
                  max={max}
                  onChange={setQuantity}
                />
                <MintButton
                  disabled={quantity === 0 || tx !== undefined}
                  onClick={onClickWhitelistMint}
                >
                  {">> Mint <<"}
                </MintButton>
              </MintContainer>

                
              </Countdown>
            </AnimatedIntroCard>
          </CardContainer> */}

      {/* <ConnectFeatureBar /> */}
    </MintMainContainer>
  );
};

export default Mint;
