import React from "react";
import { StyledContainer, StyledProgress } from "./styled";
import { ProgressBarProps } from "./types";

const ProgressBar = ({ progress, children }: ProgressBarProps) => (
  <StyledContainer>
    <StyledProgress progress={progress}>{children}</StyledProgress>
  </StyledContainer>
);

export default ProgressBar;
