import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import VisionMapOverview from './VisionmapOverview';
import { VisionMapContainer, HeroContainer } from './styled';
import Gtag from '@src/components/Gtag/Gtag';

interface Props {
  className?: string;
}

const Visionmap = ({ className }: Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const routeDetail = pathname.replace(/\/?visionmap\/?/, '') || null;
  const [currentDetail, setDetail] = React.useState<string | null>(null);

  const toggleDetail = (detail: string) => {
    const newDetail = currentDetail ? null : detail;
    setDetail(newDetail);
    navigate(`/visionmap/${newDetail || ''}`);
  };

  React.useEffect(() => {
    setTimeout(() => {
      routeDetail && toggleDetail(routeDetail);
    }, 1000);
  }, []);

  React.useEffect(() => {
    setDetail(routeDetail);
  }, [routeDetail]);

  return (
    <>
      <Gtag
        pretty_title='VISIONMAP: NEON PLEXUS'
        page_title='visionmap'
        page_path='/visionmap'
      />
      <HeroContainer>
        <video
          poster='https://cdn.neonplexus.io/assets/neonplexus-io/backgrounds/empty_background_poster.jpg'
          tabIndex={0}
          preload='preload'
          style={{ width: '100%', height: '100vh', objectFit: 'cover' }}
          autoPlay
          loop
          muted
          playsInline
        >
          <source
            type='video/webm'
            src='https://cdn.neonplexus.io/assets/neonplexus-io/backgrounds/empty_background.webm'
          />
          <source
            type='video/mp4'
            src='https://cdn.neonplexus.io/assets/neonplexus-io/backgrounds/empty_background.mov'
          />
        </video>
      </HeroContainer>
      <VisionMapContainer>
        <VisionMapOverview
          // @ts-ignore
          toggleDetail={toggleDetail}
          animator={{ activate: true }}
          activeCard={currentDetail}
        />
      </VisionMapContainer>
    </>
  );
};

export default Visionmap;
