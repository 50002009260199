import React from 'react';
import ProgressBar from '@components/ProgressBar';
import Gtag from '@src/components/Gtag/Gtag';
import { RoadmapAmountSpan } from '../styled';
const Analog = ({ title, subtitle }: any) => (
  <>
    <Gtag pretty_title='ANALOG: NEON PLEXUS' page_title='visionmap_analog' page_path='/visionmap/analog' />
    <p>
      Redeemable tokens that tie you to high quality limited edition merchandise
      and gear.
    </p>
    <p>
      <ProgressBar progress={1}>Awaiting_Handshake...</ProgressBar>
      <RoadmapAmountSpan>Unlocked when 100% of the collection has minted</RoadmapAmountSpan>
      <ul>
        <li>Apparel, especially of the foot warming variety</li>
        <li>Accessories</li>
        <li>2D Prints</li>
        <li>3D Prints</li>
      </ul>
    </p>
  </>
);

export default Analog;
