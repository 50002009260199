import React, { useEffect, useState } from 'react';
import Gtag from '@src/components/Gtag/Gtag';
import {
  BoomboxContainer,
  ButtonCont,
  ButtonPlay,
  ButtonGenerate,
  ButtonPause,
  UnityMobileWarning,
} from './styled';
import { BoomboxContext, BoomboxPlayer } from '@src/providers/BoomboxProvider';
import { CardContainer } from '@src/components/Containers';
import { AnimatedIntroCard } from '../About/styled';
import { AccentedHeader } from '@src/components/Typography';

interface IProps {
  className?: string;
}

const Play = ({ className, ...props }: IProps) => {
  const { unityInstance, status, hasPlayed, isReady, generate, play, pause } =
    React.useContext(BoomboxContext);
  const [contentEl, setContentEl] = useState(<></>);

  useEffect(() => {
    if (isReady) {
      if (unityInstance) {
        let playOrPauseEl = <></>;
        if (!hasPlayed) {
          playOrPauseEl = <></>;
        } else if (status === BoomboxPlayer.PLAYING) {
          playOrPauseEl = <ButtonPause onClick={pause} />;
        } else {
          playOrPauseEl = <ButtonPlay onClick={play} />;
        }

        setContentEl(
          <ButtonCont>
            <p>
              <mark>
                Early prototype of Generated Music tracks for traits in a
                OVERRIDE NFT.
              </mark>
            </p>
            <ButtonGenerate onClick={generate} />
            {playOrPauseEl}
          </ButtonCont>
        );
      } else {
        setContentEl(
          <ButtonCont>
            <p>
              <mark>
                Early prototype of Generated Music tracks for traits in a
                OVERRIDE NFT.
              </mark>
            </p>
            <ButtonGenerate />
            <UnityMobileWarning>
              Not currently supported on mobile devices
            </UnityMobileWarning>
          </ButtonCont>
        );
      }
    } else {
      setContentEl(<></>);
    }
  }, [status, isReady]);

  return (
    <>
      <Gtag
        pretty_title='BOOMBOX: NEON PLEXUS'
        page_title='boombox'
        page_path='/boombox'
      />
      <div className={className}>{contentEl}</div>
      <BoomboxContainer>
        <video
          poster='https://cdn.neonplexus.io/assets/neonplexus-io/backgrounds/sneak_peek_11_web_player_poster.png'
          tabIndex={0}
          preload='preload'
          style={{ width: '100%', height: '100vh', objectFit: 'cover' }}
          autoPlay
          loop
          muted
          playsInline
        >
          <source
            type='video/webm'
            src='https://cdn.neonplexus.io/assets/neonplexus-io/backgrounds/sneak_peek_11_web_player.webm'
          />
          <source
            type='video/mp4'
            src='https://cdn.neonplexus.io/assets/neonplexus-io/backgrounds/sneak_peek_11_web_player.mov'
          />
        </video>
      </BoomboxContainer>
    </>
  );
};

export default Play;
