import styled from "styled-components";
import { createClipPath, createClipPathV2 } from "@assets/utils";
import { Button } from "@components/Buttons";
import { AccentedHeader } from "@src/components/Typography";
import { SpacedContainer } from '@components/Containers';
import { CardContainer } from "@src/components/Containers";

export const StyledColumn = styled.div`
  margin: 8px;
  margin-bottom 120px;
`;

export const MintMainContainer = styled.div`
  width: 100%;
  /* display: flex; */
  /* justify-content: space-between; */
  /* z-index: 1; */
  /* opacity: 0; */
`;

export const MintContentContainer = styled.div`
  /* width: 100%; */
  /* z-index: 1; */
  /* opacity: 0; */
  /* display: flex; */
`;

export const MintLeftContainer = styled(CardContainer)`

  /* flex-grow: 1; */
  /* width: 100%; */
  /* z-index: 1; */
  /* opacity: 0; */
`;


export const MintRightContainer = styled(CardContainer)`
  /* flex-grow: 2; */
  /* width: 100%; */
  /* z-index: 1; */
  /* opacity: 0; */
`;

export const Fabricants = styled.div`
  display: flex; 
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  /* display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr); */

  > div {
    width: 20%;

    img {
      width: 100%;
      ${createClipPath()}
    } 
  }
`;

export const FabricantContainer = styled.div`
  position: relative;
  margin: 8px;
  min-width: 40vw;

  @media ${({ theme }) => theme.breakpoints.md} {
    min-width: 30vw;
  } 

  @media ${({ theme }) => theme.breakpoints.lg} {
    min-width: 10vw;
  }
`;


export const FabricanBanner = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.accent};
  font-size: 0.75em;
  font-weight: 100;
  left: 0;
  bottom: 0;
  padding: 1px 1px;
  color: #fff;
  margin: -5px;
  opacity: .75;

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 1px 5px;
    font-size: 1em;
    bottom: 10%;
  }
`;

export const FabricanBannerText = styled.div`
  position: absolute;
  font-family: 'Jost', sans-serif;
  font-size: 0.75em;
  font-weight: 100;
  left: 0;
  bottom: 0;
  padding: 1px 1px;
  color: #fff;
  margin: -5px;

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 1px 5px;
    font-size: 1em;
    bottom: 10%;
  }

  mark {
    background-image: linear-gradient( to right, rgba(255,0,180,.75), rgba(0,227,254,.75));
  }
`;

export const FabricanContainer = styled(SpacedContainer)`
  font-size: 0.75em;

  svg {
    display: none;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 0 10px;
    font-size: 1em;
    svg {
      display: block;
    }
  }
`;



export const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: calc(100% - 16px);

  @media ${({ theme }) => theme.breakpoints.lg} {
    width: auto;
  }

  margin: 8px;
  font-size: 1.6em;
  color: rgb(0, 227, 254);
  font-weight: bold;
  font-family: "Jost", sans-serif;
`;

export const InfoUl = styled.ul`
  /* list-style: none; */
`;

export const MintDateSpan = styled.span`
  color: white;
`;

export const DiscordUrlSpan = styled.span`
  margin: 0 0 0 8px;
`;

export const MintContainer = styled.div`
  margin: 5px 0 5px 0;
  display: flex;
  align-content: right;
  justify-content: right;
`;

export const MintButton = styled(Button)`
  padding: 0 16px;
  font-family: "NeonVampire", sans-serif;
  font-size: 36px;
  white-space: nowrap;
  opacity: 1;
  padding: 10px 20px 10px 20px;
  color: #000;
  background-color: #f6ff00;
  border: 0;
 
  ${createClipPathV2()}

  .mega {
    font-size: 2em;
    line-height: 0.5em;
    margin-left: 0.1em;
  }

  &:focus {
    &:not(:disabled),
    &:not(:hover),
    &:not(:active) {
      color: #000;
      background-color: #00ff22;;
    }
  }

  &:hover {
    &:not(:disabled) {
      transform: translateY(2px);
      color: #000;
      background-color: #00ff22;
    }
  }

  &:active {
    &:not(:disabled) {
      transform: translateY(2px);
      color: #000;
      background-color: #00ff22;
    }
  }
`;


export const ConnectButton = styled(Button)`
  padding: 0 16px;
  font-family: "NeonVampire", sans-serif;
  font-size: 36px;
  white-space: nowrap;
  opacity: 1;
  padding: 10px 20px 10px 20px;
  color: #000;
  background-color: #f6ff00;
  border: 0;

  ${createClipPathV2()}

  .mega {
    font-size: 3em;
    line-height: 0.5em;
    margin-left: 0.1em;
  }

  &:focus {
    &:not(:disabled),
    &:not(:hover),
    &:not(:active) {
      color: #000;
      background-color: #00ff22;;
    }
  }

  &:hover {
    &:not(:disabled) {
      transform: translateY(2px);
      color: #000;
      background-color: #00ff22;
    }
  }

  &:active {
    &:not(:disabled) {
      transform: translateY(2px);
      color: #000;
      background-color: #00ff22;
    }
  }
`;

export const AccentedHeaderSmaller = styled(AccentedHeader)`
  /* font-size: 2em; */
  
  span {
    font-size: .5em;
    vertical-align: middle;
    display: inline-block;
    color: white;
    background-color: red;
    font-family: Jost, sans-serif;
    font-weight: bold;
    padding: 2px;
    margin: 0 10px 0 10px;
    border-radius: 10px;

    &:after {
      content: none;
    }
  }
  /* align-content: center;
  justify-content: center;
  text-align: center; */
`

export const CountdownContainer = styled.div`
  height: 13vh;
  text-align: center;
  background: rgba(0, 0, 0, 0.125);
  opacity: 0.8;
`;

export const GreenMarkBase = styled.mark`
  background-color: #2fe10f;
  color: white;
`;

export const GreenMarkActive = styled(GreenMarkBase)`
  box-shadow: 0 0 20px 1px #2fe10f;
`;


export const GreenMarkInactive = styled.mark`
  background-color: #26ae0e;
  color: white;
`;

export const ButtonMint = styled.button`
  background-image: url("images/boombox/generate.png");
  background-color: transparent;
  border-style: none;
  width: 300px;
  height: 80px;

  &:active {
    &:not(:disabled) {
      transform: translateY(2px);
    }
  }
`;

export const WhitelistIndicator = styled.button<any>`
  display: flex;
  width: 100%;
  font-size: 20px;
  border: 0px;
  margin-top: 5px;
  color: white;
  align-items: center;
  font-family: Jost, sans-serif;
  background: transparent;
  background-image: linear-gradient(
    to right,
    rgba(255, 0, 180, 0.25),
    rgba(0, 227, 254, 0.25)
  );

  img {
    height: 50px;
  }

  .description {
    margin-left: 25px;
  }

  .price {
    margin-left: auto;
    padding-right: 20px;
    color: white;
    weight: 1em;
  }

  .minted {
    margin-left: auto;
    padding-right: 20px;
    color: #f6ff00;
    weight: 1em;
  }

  ${({ expired }) =>
    expired ? ` background-color: rgba(0,0,0,1); opacity: 0.5;` : ""};

  ${({ active }) => (active ? ` box-shadow: 0 0 5px #ff00b4;` : "")};
`;

// import ConnectButton from '../TransactButton';

// export const StyledConnectButton = styled(ConnectButton)`
//   padding: 0 0 0 20px;

//   & button {
//     min-width: 100px;
//   }
// `;

// export default StyledConnectButton;
