import { Columns } from "@components/Containers";
import Countdown from "../Countdown";

import Mint from "./Mint";
import { CountdownContainer } from "./styled";
interface IProps {
  className?: string;
}

const Transactions = ({ className, ...props }: IProps) => {
  return (
    <Columns>
      <Mint />
    </Columns>
  );
};

export default Transactions;
