import { Flexkit, Metacity, Analog, Plexus } from './Details';

export const DETAIL_MAP = new Map([
  [
    'flexkit',
    {
      title: 'Flexkit',
      subtitle: 'Your Creative Toolkit',
      content: Flexkit,
      url: 'https://cdn.neonplexus.io/assets/neonplexus-io/sneakpeek-samples/website_9_2500',
    },
  ],
  [
    'metacity',
    {
      title: 'Meta City',
      subtitle: 'The NEON PLEXUS Universe',
      content: Metacity,
      url: 'https://cdn.neonplexus.io/assets/neonplexus-io/sneakpeek-samples/website_10_2500',
    },
  ],
  [
    'analog',
    {
      title: 'Analog',
      subtitle: 'Tokenized Merch & Drip',
      content: Analog,
      url: 'https://cdn.neonplexus.io/assets/neonplexus-io/sneakpeek-samples/website_7_2500',
    },
  ],
  [
    'plexus',
    {
      title: 'Plexus',
      tinyTitle: 'The',
      subtitle: 'NFT Community Platform',
      content: Plexus,
      url: 'https://cdn.neonplexus.io/assets/neonplexus-io/sneakpeek-samples/website_8_2500',
    },
  ],
]);
