import React from 'react';
import ProgressBar from '@components/ProgressBar';
import { AnimatedCard, CardContainer } from '@components/Cards';
import { PlexusCard } from './styled';
import Gtag from '@src/components/Gtag/Gtag';
import { RoadmapAmountSpan } from '../styled';

const Plexus = ({ title, subtitle }: any) => (
  <>
    <Gtag pretty_title='PLEXUS: NEON PLEXUS' page_title='visionmap_plexus' page_path='/visionmap/plexus' />
    <p>
      As we build NEON PLEXUS, we are always thinking about how we could share
      the tools and technologies we’ve built to empower other creators and their
      communities.
    </p>
    <ProgressBar progress={1}>Awaiting_Handshake...</ProgressBar>
    <RoadmapAmountSpan>Unlocked when 100% of the collection has minted</RoadmapAmountSpan>
    <CardContainer direction='horizontal'>
      <PlexusCard>
        <h3>Plexus Create</h3>
        <ul>
          <li>2D/3D Unity SDK</li>
          <li>Collection Creation</li>
        </ul>
      </PlexusCard>
      <PlexusCard>
        <h3>Plexus Community</h3>
        <ul>
          <li>Community Tools & Events</li>
          <li>Tuning & Analytics</li>
        </ul>
      </PlexusCard>
    </CardContainer>
    <CardContainer direction='horizontal'>
      <PlexusCard>
        <h3>Plexus Publish</h3>
        <ul>
          <li>Collaboration</li>
          <li>Marketing</li>
          <li>Discovery</li>
        </ul>
      </PlexusCard>
      <PlexusCard>
        <h3>Plexus Play</h3>
        <ul>
          <li>Flexkit</li>
          <li>Holder Perks</li>
          <li>Gamification & GameFi</li>
        </ul>
      </PlexusCard>
    </CardContainer>
  </>
);

export default Plexus;
