import React from "react";
import { CardContainer, Card, LeftContainer } from "@components/Containers";
import MintFeatureBar from "@src/widgets/MintFeatureBar";
import Transactions from "@src/widgets/Transactions";
import { HeroContainer } from "../About/styled";
import Gtag from "@src/components/Gtag/Gtag";

interface IProps {
  className?: string;
}

const Mint = ({ className, ...props }: IProps) => (
  <>
    <Gtag
      pretty_title='MINT: NEON PLEXUS'
      page_title='mint'
      page_path='/mint'
    />
    <HeroContainer>
      <video
        poster="https://cdn.neonplexus.io/assets/neonplexus-io/backgrounds/mint_background_poster.png"
        tabIndex={0}
        preload="preload"
        style={{ width: "100%", height: "100vh", objectFit: "cover" }}
        autoPlay
        loop
        muted
        playsInline
      >
        <source
          type="video/webm"
          src="https://cdn.neonplexus.io/assets/neonplexus-io/backgrounds/mint_background.webm"
        />
        <source
          type="video/mp4"
          src="https://cdn.neonplexus.io/assets/neonplexus-io/backgrounds/mint_background.mov"
        />
      </video>
    </HeroContainer>
    {/* <MintFeatureBar /> */}
    <Transactions />
  </>
);

export default Mint;
