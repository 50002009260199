// @ts-nocheck

import React from 'react';
import styled from 'styled-components';
import anime from 'animejs';
import {
  AnimatorRef,
  AnimatorClassSettings,
  withAnimator,
} from '@arwes/animation';

import { AnimatedCardContainer, OverlayedSVG, StyledPath } from './styled';

const getDirectionalMargins = (margin: string) => ({
  horizontal: `
    margin-right: ${margin};
    &:last-child {
      margin-right: 0;
    }
  `,
  vertical: `
    margin-bottom: ${margin};

    &:last-child {
      margin-bottom: 0;
    }
  `,
});

const flexDirectionMapping = {
  horizontal: 'row',
  vertical: 'column',
};

export const Card = styled.div`
  padding: 8px 16px;
  width: 100%;

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 16px 32px;
  }
`;

type CardContainerProps = {
  direction: 'vertical' | 'horizontal';
};
export const CardContainer = styled.div`
  display: flex;
  margin: 8px;

  @media ${({ theme }) => theme.breakpoints.md} {
    margin-bottom: 16px;
  }

  flex-direction: ${({ direction }: CardContainerProps) =>
    flexDirectionMapping[direction]};

  > ${Card}, > ${AnimatedCardContainer} {
    ${({ direction }) => getDirectionalMargins('16px')[direction]}
  }
`;
