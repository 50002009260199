import React from 'react';
import { Twitter } from '@styled-icons/simple-icons';

import { IconLink, Link } from '@components/Links';
import {
  TeamMembers,
  TeamMemberContainer,
  TeamMemberBanner,
  TeamSpacedContainer as SpacedContainer,
} from './styled';
import { PlazaCode } from '../About/styled';

interface IProps {
  className?: string;
}

const team = [
  {
    name: '0xcompbow',
    social: 'https://twitter.com/0xcompbow',
    img: 'images/team/pfp_0xcompbow.png',
  },
  {
    name: 'pixelpolyn8r',
    social: 'https://twitter.com/pixelpolyn8r',
    img: 'images/team/pfp_pixelpolyn8r.png',
  },
  {
    name: 'McBastage',
    social: 'https://twitter.com/McBastage',
    img: 'images/team/pfp_McBastage.png',
  },
  {
    name: 'rusticsoup',
    social: 'https://twitter.com/rustic_soup',
    img: 'images/team/pfp_rusticsoup.png',
  },
  {
    name: 'k0blin',
    social: 'https://twitter.com/k0blin',
    img: 'images/team/pfp_k0blin.png',
  },
];

const Team = ({ className, ...props }: IProps) => (
  <TeamMembers>
    {team.map((member) => (
      <div key={member.name}>
        <Link href={member.social}>
          <TeamMemberContainer>
            <img src={member.img} alt={`${member.name}'s profile'`} />
            {member.name === 'pixelpolyn8r' ? (
              <><TeamMemberBanner>artist 👑</TeamMemberBanner>
              <PlazaCode>pnoodler.pdoodler</PlazaCode></>
            ) : (
              ''
            )}
          </TeamMemberContainer>
        </Link>
        <SpacedContainer>
          <IconLink href={member.social}>
            <Twitter />
          </IconLink>
          <Link href={member.social}>{member.name}</Link>
        </SpacedContainer>
      </div>
    ))}
  </TeamMembers>
);

export default Team;
