import styled from 'styled-components';

export const Title = styled.h1(({ theme }) => ({
  fontSize: theme.fontSizes.normal,
  marginBottom: theme.spacing.lg,
  marginRight: 'auto',
  marginLeft: 'auto',
  // fontWeight: theme.fontWeights.normal,
}));

export const Accent = styled.span`
  font-family: 'RoadRage', sans-serif;
  font-size: 2em;
  color: ${({ theme }) => theme.colors.accentSecondary};
`;

export const AccentedHeader = styled.h1`
  word-break: break-word;
  display: block;

  span {
    display: inline-block;
    color: ${({ theme }) => theme.colors.accentSecondary};

    &:after {
      content: '//';
      color: ${({ theme }) => theme.colors.secondary};
      letter-spacing: -0.125em;
      word-break: keep-all;
    }
  }

  &.small-line {
    margin-bottom: -0.25em;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    span,
    + span {
      display: inline;
    }
  }
`;
