import { StyledNavItem } from './styled';

interface IProps {
  className?: string;
}

export const Logo = ({ className, ...props }: IProps) => (
  <StyledNavItem className={className} url='/'>
    NEON PLEXUS
  </StyledNavItem>
);

export default Logo;
