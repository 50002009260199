import React from 'react';
import ProgressBar from '@components/ProgressBar';
import Gtag from '@src/components/Gtag/Gtag';
import { Link } from '@src/components/Links';
import { RoadmapAmountSpan } from '../styled';
const Flexkit = ({ title, subtitle }: any) => (
  <>
    <Gtag pretty_title='FLEXKIT: NEON PLEXUS' page_title='visionmap_flexkit' page_path='/visionmap/flexkit' />
    <p>
    We aim to bring you flexible, useful, and strange tools for your OVERRIDE NFT’s to both use and create your own personal NEON PLEXUS content.
    </p>
    <p>
      <ProgressBar progress={50}>Soon</ProgressBar>
      <RoadmapAmountSpan>Unlocked when 50% of the collection has minted</RoadmapAmountSpan>
      <ul>
        <li>Generative Music that drives an Animated NFT</li>
        <li>Ultra high resolution images</li>
      </ul>
    </p>
    <p>
      <ProgressBar progress={10}>Buffering...</ProgressBar>
      <RoadmapAmountSpan>Unlocked when 100% of the collection has minted</RoadmapAmountSpan>
      <ul>
        <li>Real-time 3D iOS and Android Mobile App</li>
        <ul>
          <li>Face tracking</li>
          <li>Toggle Gear Traits, Rotate, Crop</li>
          <li>Alternative Environments (NFTs, …)</li>
          <li>Mint derivative content</li>
        </ul>
        <li>
          Other content use and creation tools for PFP, Social, Gaming, and
          Streaming use cases
        </li>
      </ul>
    </p>
  </>
);

export default Flexkit;
