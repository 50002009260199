import styled from 'styled-components';
import { size as breakpointSize } from '@theme/breakpoints';

const getDirectionalMargins = (margin: string) => ({
  horizontal: `
    margin-right: ${margin};
    &:last-child {
      margin-right: 0;
    }
  `,
  vertical: `
    margin-bottom: ${margin};

    &:last-child {
      margin-bottom: 0;
    }
  `,
});

const flexDirectionMapping = {
  horizontal: 'row',
  vertical: 'column',
};

export const Columns = styled.div`
  @media ${({ theme }) => theme.breakpoints.md} {
    display: flex;

    > * {
      flex: 1;
    }
  }
`;

export const FlexContainer = styled.div`
  @media ${({ theme }) => theme.breakpoints.md} {
    display: flex;
  }
`;

export const SpacedContainer = styled.div`
  justify-content: space-between;

  @media ${({ theme }) => theme.breakpoints.md} {
    display: flex;
  }
`;

export const LeftContainer = styled.div`
  width: 100%;
  margin-right: ${({ theme }) => theme.spacing.xl};

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 70%;
  }
`;

export const LeftJustified = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StickyFooter = styled.div`
  position: fixed;
  bottom: 0;
  width: 100vw;
  display: flex;
  padding: ${({ theme }) => theme.spacing.md};
  justify-content: space-between;
`;

export const Card = styled.div`
  // border: 1px solid white;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 16px;
  width: 100%;
`;

type CardContainerProps = {
  direction: 'vertical' | 'horizontal';
};
export const CardContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
  flex-direction: ${({ direction }: CardContainerProps) =>
    flexDirectionMapping[direction]};

  > ${Card} {
    ${({ direction }) => getDirectionalMargins('16px')[direction]}
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.xxl};
  margin-bottom: ${({ theme }) => theme.spacing.xxl};
`;

export const ContentContainer = styled.div`
  margin-top: 48px;
  padding-top: 0px;
  max-height: calc(100vh - 48px);
  overflow: scroll;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  @media ${({ theme }) => theme.breakpoints.sm} {
    padding-top: 8px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    max-width: ${breakpointSize.md - 48}px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 48px;
  }
  @media ${({ theme }) => theme.breakpoints.lg} {
    max-width: ${breakpointSize.lg - 60}px;
  }
  @media ${({ theme }) => theme.breakpoints.xl} {
    max-width: ${breakpointSize.xl - 80}px;
  }
  @media ${({ theme }) => theme.breakpoints.xxl} {
    max-width: ${breakpointSize.xl}px;
  }
`;
