// @ts-nocheck

import React, { FC, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import {
  AnimatedCard,
  StyledVideo,
  StyledCard,
  duration,
  STAGGER,
} from './styled';
import { DETAIL_MAP } from './constants';

interface Props {
  className?: string;
  title: string;
  subtitle?: string;
  url?: string;
  closeCard?: any;
  active: boolean;
  index: number;
}

const CardBackground = ({ url }) => (
  <StyledVideo autoPlay muted playsInline>
    <source type='video/webm' src={url + '.webm'} />
    <source type='video/mp4' src={url + '.mov'} />
  </StyledVideo>
);

const VisionmapCard: FC<Props> = ({ id, onClick, activeCard, index }) => {
  const detailConfig = DETAIL_MAP.get(id);
  const cardRef = React.useRef(null);

  const renderBackground = () => <CardBackground url={detailConfig.url} />;

  return (
    detailConfig && (
      <CSSTransition
        in={activeCard === id || activeCard === null}
        mountOnEnter
        appear
        timeout={{
          enter: duration.enter + index * STAGGER,
          exit: duration.exit,
        }}
        duration={duration}
        index={index}
      >
        <AnimatedCard
          open={activeCard === id}
          hidden={activeCard !== id && activeCard !== null}
          ref={cardRef}
          expanded={activeCard === id}
        >
          <StyledCard
            onClick={onClick}
            id={id}
            renderBackground={renderBackground}
            index={index}
            cardWidth={cardRef.current && cardRef.current.offsetWidth}
            expanded={activeCard === id}
          >
            <h2 className='tinyTitle'>{detailConfig.tinyTitle}</h2>
            <h2>{detailConfig.title}</h2>
            <legend>{detailConfig.subtitle}</legend>
          </StyledCard>
        </AnimatedCard>
      </CSSTransition>
    )
  );
};

export default VisionmapCard;
