import React from 'react';
import { FlexContainer, LeftContainer } from '@components/Containers';
import { CardContainer } from '@components/Cards';
import { Accent, AccentedHeader } from '@components/Typography';
import Team from '@pages/Team';

import {
  AnimatedIntroCard,
  HeroContainer,
  StyledVideoContainer,
  StyledVideo,
  FadedImg,
  FadedImgBottom,
  SneakPeeks,
  TinyTitle,
  PlazaCode,
} from './styled';
import { PlazaLink } from '@src/components/Links';
import { HiddenQR } from '../Visionmap/styled';
import Gtag from '@src/components/Gtag/Gtag';

interface IProps {
  className?: string;
}

const About = ({ className, ...props }: IProps) => (
  <>
    <Gtag
      pretty_title='ABOUT: NEON PLEXUS'
      page_title='about'
      page_path='/about'
    />
    <div className={className}>
      <CardContainer direction='horizontal' className='darkBg spread'>
        <AnimatedIntroCard>
          <FlexContainer>
            <LeftContainer>
              <AccentedHeader className='small-line'>
                A Rebellion
              </AccentedHeader>
              <AccentedHeader>
                <span>is brewing</span>
              </AccentedHeader>
              <p>
                <mark>Seeking 9000 Iconoclasts and Code Breakers.</mark>
              </p>
              <p>
                Welcome to Meta City, a utopian city of the future. There are no
                dissidents, degenerates or criminals, only “unsocialised” whose
                fates are unspoken. OVERRIDE Meta City Fabricants to resist the
                last 300 years of establishment lies!
              </p>
              <p>
                <Accent>OveRridE </Accent>is a generative collection of 9000 NFT
                Fabricants. <br />
                <mark>
                  Acquire them and decide the fate of{' '}
                  <a href='visionmap/metacity'>META CITY!</a>
                </mark>
              </p>
            </LeftContainer>
            <StyledVideoContainer>
              <StyledVideo autoPlay muted playsInline>
                <source
                  type='video/webm'
                  src='https://cdn.neonplexus.io/assets/neonplexus-io/sneakpeek-samples/website_6_2500.webm'
                />
                <source
                  type='video/mp4'
                  src='https://cdn.neonplexus.io/assets/neonplexus-io/sneakpeek-samples/website_6_2500.mov'
                />
              </StyledVideo>
            </StyledVideoContainer>
          </FlexContainer>
        </AnimatedIntroCard>
      </CardContainer>

      <CardContainer direction='horizontal' className='opaqueBg spread'>
        <AnimatedIntroCard>
          <FlexContainer>
            <FadedImg src='https://cdn.neonplexus.io/assets/neonplexus-io/other/sneakpeek-8-v2.png' />
            <div>
              <AccentedHeader>
                Plexus <span>Network</span>
              </AccentedHeader>
              <p>
                <mark>
                  Your direct connection to the exclusive Plexus network.
                </mark>
              </p>
              <p>
                Your digital identity will open additional experiences for you.
                You are gaining an exclusive direct connection to the Neon
                Plexus network and each other.
              </p>
              <PlazaCode>enon.uxlpse.hodler</PlazaCode>
            </div>
            <FadedImgBottom src='https://cdn.neonplexus.io/assets/neonplexus-io/other/sneakpeek-8.png' />
          </FlexContainer>
          {/*
          <SneakPeeks>
            <div>
              <img src='images/sp05.png' />
            </div>
            <div>
              <img src='images/ws02.png' />
            </div>
            <div>
              <img src='images/sp04d.png' />
            </div>
            <div>
              <img src='images/ws01.png' />
            </div>
            <div>
              <img src='images/ws04.png' />
            </div>
          </SneakPeeks>
          */}
        </AnimatedIntroCard>
      </CardContainer>

      <CardContainer direction='horizontal' className='darkBg spread'>
        <AnimatedIntroCard>
          <TinyTitle>Rebel</TinyTitle>
          <AccentedHeader>
            Team<span></span>
          </AccentedHeader>
          <HiddenQR src='images/other/qr-73-2.png' />
          <p>
            <mark>We are here to create, build, and share.</mark>
          </p>
          <p>
            We have been working hard on NEON PLEXUS since Q3 2021. We are
            excited to share our work and our <a href='visionmap'>VISION</a>{' '}
            with the community.
          </p>

          <p>
            <mark>
              We are game industry{' '}
              <PlazaLink
                href='/api/uuid/36d4c682-86ee-4796-83b8-129685f4c7ce'
                target='_blank'
              >
                veterans
              </PlazaLink>
              . 🎮
            </mark>
          </p>
          <p>
            Among the 5 of us, we have at least 6 decades of games industry
            experience on top charting console and mobile games. We are long
            time friends and colleagues with a diverse set of skills and
            backgrounds. We have experience creating high quality art,
            engineering fully scalable and secure systems, designing fun and
            interactive experiences, creating tools for developers, managing
            teams, and maintaining live games. We are excited to leverage the
            skills we’ve honed over the years to bring the best experiences in
            the NFT space.
          </p>
          <Team />
        </AnimatedIntroCard>
      </CardContainer>

      <CardContainer direction='horizontal' className='spread'>
        <div className='centered'>
          © 2022 NEON PLEXUS |{' '}
          <a href='mailto:team@neonplexus.io'>Team Contact</a>
        </div>
      </CardContainer>
    </div>
    <HeroContainer>
      <video
        poster='https://cdn.neonplexus.io/assets/neonplexus-io/backgrounds/empty_background_poster.jpg'
        tabIndex={0}
        preload='preload'
        style={{ width: '100%', height: '100vh', objectFit: 'cover' }}
        autoPlay
        loop
        muted
        playsInline
      >
        <source
          type='video/webm'
          src='https://cdn.neonplexus.io/assets/neonplexus-io/backgrounds/empty_background.webm'
        />
        <source
          type='video/mp4'
          src='https://cdn.neonplexus.io/assets/neonplexus-io/backgrounds/empty_background.mov'
        />
      </video>
    </HeroContainer>
  </>
);

export default About;
