import StyledAbout from "@pages/About";
import Visionmap from "@pages/Visionmap";
import Mint from "@pages/Mint";
import Play from "./pages/Play";
import { Navigate } from "react-router-dom";



const getRoutes = (
  pageMint: boolean,
  pageDebug: boolean,
  pageWhitelist: boolean
) => {
  let routes = [
    {
      path: "/",
      element: <Mint />,
      index: true,
    },
    {
      path: "/about",
      element: <StyledAbout />,
      index: false,
    },
    {
      path: "/whitelist",
      element: <Navigate to="/" />,
      index: false,
    },
    {
      path: "/mint",
      element: <Navigate to="/" />,
      index: false,
    },
    {
      path: "/visionmap",
      element: <Visionmap />,
      children: [
        {
          path: "plexus",
          element: <Visionmap />,
          index: false,
        },
        {
          path: "analog",
          element: <Visionmap />,
          index: false,
        },
        {
          path: "flexkit",
          element: <Visionmap />,
          index: false,
        },
        {
          path: "metacity",
          element: <Visionmap />,
          index: false,
        },
      ],
    },
    {
      path: "/boombox",
      element: <Play />,
      index: false,
    }
  ];

  if (pageMint) {
    routes.push({
      path: "/mint",
      element: <Mint />,
      index: false,
    });
  }

  return routes;
};

export default getRoutes;
