import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

export enum BoomboxPlayer {
  PLAYING = 'playing',
  PAUSED = 'paused',
  STOPPED = 'stopped',
}

interface IBoomboxContext {
  unityInstance:any;
  status:BoomboxPlayer;
  hasPlayed:boolean;
  isReady:boolean;
  generate: () => void;
  play: () => void;
  pause: () => void;
}

const UnityCont = styled.div`
    display: none; 
`;

export const BoomboxContext = React.createContext<IBoomboxContext>({
  unityInstance: null,
  status: BoomboxPlayer.STOPPED,
  hasPlayed: false,
  isReady: false,
  generate: () => {},
  play: () => {},
  pause: () => {},
});

const BoomboxProvider = ({ children }: any) => {
  const canvasEl = useRef<HTMLCanvasElement>(null);
  const [unityInstance, setUnityInstance] = useState<any>(null);
  const [mobile, setMobile] = useState<boolean|null>(null);
  const [status, setStatus] = useState<BoomboxPlayer>(BoomboxPlayer.STOPPED);
  const [hasPlayed, setHasPlayed] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);

  const buildUrl = "music-mixer/Build";
  const loaderUrl = buildUrl + "/generative_music_prototype_builds.loader.js";
  const config:any = {
    dataUrl: buildUrl + "/generative_music_prototype_builds.data",
    frameworkUrl: buildUrl + "/generative_music_prototype_builds.framework.js",
    codeUrl: buildUrl + "/generative_music_prototype_builds.wasm",
    streamingAssetsUrl: "StreamingAssets",
    companyName: "DefaultCompany",
    productName: "generative_music_prototype_v2",
    productVersion: "0.1",
  };

  useEffect(() => {
    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      setMobile(true);
      // // Avoid draining fillrate performance on mobile devices,
      // // and default/override low DPI mode on mobile browsers.
      config.devicePixelRatio = 1;
    } else {
      setMobile(false);
    }
  }, []);

  useEffect(() => {
    if (mobile !== null && !mobile) {

      const script = document.createElement('script');
      script.src = loaderUrl;
      script.async = true;
      
      script.onload = () => {
        (window as any).createUnityInstance(canvasEl.current, config, (progress:number) => {
          // progressBarFull.style.width = 100 * progress + "%";
          console.log((100 * progress) + "%");
        }).then((_unityInstance:any) => {
          setUnityInstance(_unityInstance);
          setTimeout(() => {
            setIsReady(true);
          }, 1000);
          //   // loadingBar.style.display = "none";
        }).catch((err:Error) => {
          console.error(err);
          // setIsReady(true);
        });
      };
  
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      }
    } else if (mobile !== null) {
      setIsReady(true);
    }
  }, [mobile]);

  const generate = () => {
    if (unityInstance) {
      console.log("WebPlayerPlayNewRandom");
      unityInstance.SendMessage('generative_music_prototype', 'WebPlayerPlayNewRandom');
      setStatus(BoomboxPlayer.PLAYING);
      setHasPlayed(true);
    }
  }  
  
  const play = () => {
    console.log("WebPlayerPlayOrResumeAudio");
    if (unityInstance) {
      console.log("WebPlayerPlayOrResumeAudio");
      unityInstance.SendMessage('generative_music_prototype', 'WebPlayerPlayOrResumeAudio');
      setStatus(BoomboxPlayer.PLAYING);
      setHasPlayed(true);
    }
  }

  const pause = () => {
    if (unityInstance) {
      console.log("WebPlayerPause");
      unityInstance.SendMessage('generative_music_prototype', 'WebPlayerPause');
      setStatus(BoomboxPlayer.PAUSED);
    }
  }

  return (
    <BoomboxContext.Provider
      value={{
        unityInstance,
        status,
        hasPlayed,
        isReady,
        generate,
        play,
        pause
      }}
    >
      <>
        <UnityCont>
          <canvas ref={canvasEl} />
        </UnityCont>
        {children}
      </>
    </BoomboxContext.Provider>
  );
};
export default BoomboxProvider;
