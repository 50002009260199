import styled from 'styled-components';
import defaultTheme from '@theme/theme';
import { LinkProps, Type } from './types';

export const StyledLink = styled.a<LinkProps & { as?: any }>(
  ({ linkType: type = Type.PRIMARY, theme = defaultTheme, disabled }) => ({
    color: disabled ? theme.colors.disabled : theme.colors.secondary,
    textDecoration: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: disabled ? 'not-allowed' : 'pointer',
    ...(disabled
      ? {}
      : {
          '&:hover, &:active': {
            color: theme.colors.primary,
            svg: {
              fill: theme.colors.primary,
            },
          },
        }),
    ...getKindStyle(type, theme),
  })
);

function getKindStyle(type: Type, theme: any) {
  switch (type) {
    case Type.ICON:
      return {
        svg: {
          height: 20,
          width: 20,
          '&:hover': {
            color: 'blue',
          },
          '&:active': {
            color: 'blue',
          },
        },
      };
    default:
      return {
        padding: `${theme.spacing.xs} ${theme.spacing.md}`,
      };
  }
}
