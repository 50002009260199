import React, { CSSProperties, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { NotificationContext } from '@providers/NotificationProvider';
import { LinkProps, Type } from './types';
import { StyledLink } from './styled';
import { reduceEachLeadingCommentRange } from 'typescript';

export const Link = ({
  href,
  className,
  linkType = Type.PRIMARY,
  children,
  disabled,
  internal = false,
  ...props
}: LinkProps) => {
  // const { toggleNotificationPanel } = React.useContext(NotificationContext);

  const onClickLink = () => {
    const shortUrl = href.split('?')[0];
    (window as any).gtag('event', shortUrl, {
      'event_category': 'Outgoing Links'
    });

    if (shortUrl === "https://neonplexus.io/discord") {
      // console.log(`shortUrl: ${shortUrl}`);
      (window as any).gtag('event', 'conversion', {
          'send_to': 'AW-10827763561/66Y4CPrZ_qUDEOmeiqso'
      });
    }
  };

  let as: any = 'a';
  if (disabled) {
    as = 'span';
  } else if (internal) {
    as = RouterLink;
  }

  return (
    // @ts-ignore
    <StyledLink
      className={className}
      onClick={onClickLink}
      href={href}
      to={href}
      linkType={linkType}
      disabled={disabled}
      as={as}
      target={internal ? '_self' : '_blank'}
      {...props}
    >
      {children}
    </StyledLink>
  );
};

export const IconLink = ({ children, ...props }: LinkProps) => (
  <Link {...props} linkType={Type.ICON}>
    {children}
  </Link>
);

const plazaStyle: CSSProperties = {
  color: 'white',
  padding: '0px'
};

export const PlazaLink = ({ children, ...props }: LinkProps) => (
  <Link {...props} style={plazaStyle}>{children}</Link>
);
