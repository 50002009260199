import styled from 'styled-components';
import { SVGButton } from '@components/Buttons';
// import { size as breakpointSize } from '@theme/breakpoints';
import { ProgressBarProps } from './types';

export const StyledContainer = styled.div`
  font-family: 'OCR-A', sans-serif;
  min-height: 10px;
  border: 1px solid white;
  padding: ${({ theme }) => theme.spacing.sm};
  font-size: 0.75em;
`;

export const StyledProgress = styled.div<ProgressBarProps>`
  background-color: ${({ theme }) => theme.colors.accent};
  width: ${({ progress }) => progress}%;
  height: 100%;
`;
