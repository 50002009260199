import { useEffect } from "react";
import { IProps } from "./types";

const Gtag = ({ className, ...props }: IProps) => {
    useEffect(() => {
        document.title = props.pretty_title;  
        (window as any).gtag('event', 'page_view', {
            'page_title' : props.page_title,
            'page_path' : props.page_path,
            'send_to' : 'G-9XQ4ZXYNTQ'
        });
    }, []);

    return (<></>);
}

export default Gtag;