import { createContext, useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { truncatedAddress } from '@utils/ethereumUtils';
import { toast } from 'react-toastify';
import detectEthereumProvider from '@metamask/detect-provider';

// https://docs.metamask.io/guide/ethereum-provider.html#errors

interface IAccount {
  signer: ethers.Signer;
  address: string;
  shortAddress: string;
  balance: string;
  displayBalance: string;
}

interface IEthereumContext {
  connect: () => Promise<ConnectionStatus>;
  account: IAccount | undefined;
  status: ConnectionStatus;
  blockNumber: number;
  getAccount: () => void;
  provider: ethers.providers.Web3Provider | undefined;
}

export enum ConnectionStatus {
  DISCONNECTED = 'disconnected',
  CONNECTED = 'connected',
  CONNECTING = 'connecting',
}

// mainnet
const CHAIN_ID = '0x1';
// rinkeby
// const CHAIN_ID = '0x4';

interface IProviderRpcError extends Error {
  message: string;
  code: number;
  data?: unknown;
}

interface IProviderMessage {
  type: string;
  data: unknown;
}

export const EthereumContext = createContext<IEthereumContext>({
  connect: async () => ConnectionStatus.DISCONNECTED,
  account: undefined,
  status: ConnectionStatus.DISCONNECTED,
  blockNumber: -1,
  getAccount: () => {},
  provider: undefined,
});

const fetchAccount = async (
  provider: ethers.providers.Web3Provider
): Promise<IAccount | undefined> => {
  if (!provider) throw new Error('Provider not setup');

  try {
    const signer: ethers.Signer = provider.getSigner();
    const address = await signer.getAddress();
    const balance = await signer.getBalance();
    balance.mod(1e15);

    let _displayBalance: string = ethers.utils.formatEther(balance);
    _displayBalance = (
      Math.round(parseFloat(_displayBalance) * 1e3) / 1e3
    ).toString();

    return {
      signer,
      address,
      shortAddress: truncatedAddress(address),
      balance: ethers.utils.formatEther(balance),
      displayBalance: _displayBalance,
    };
  } catch (err: any) {
    toast.error(err);
    return;
  }
};

const EthereumProvider = ({ children }: any) => {
  const [account, setAccount] = useState<IAccount>();
  const [status, setStatus] = useState(ConnectionStatus.DISCONNECTED);
  const [ethereum, setEthereum] = useState<any>();
  const [provider, setProvider] = useState<ethers.providers.Web3Provider>();
  const [blockNumber, setBlockNumber] = useState<number>(-1);
  // const chainId = await ethereum.request({ method: 'eth_chainId' });

  useEffect(() => {
    const fetchEthProvider = async () => {
      let ethereum = await detectEthereumProvider({
        // mustBeMetaMask: true,
        timeout: 2000,
      });
      if (ethereum) {
        try {
          ethereum = 
            // @ts-ignore
            (await ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: CHAIN_ID }],
            })) || ethereum;
        } catch (err) {
          console.error(err);
        }
        setEthereum(ethereum);
      }
    };
    fetchEthProvider();
  }, []);

  useEffect(() => {
    if (ethereum) {
      setProvider(new ethers.providers.Web3Provider(ethereum));
    }
  }, [ethereum]);

  useEffect(() => {
    const onAccountChanged = (accounts: string[]) => {
      if (accounts.length === 0) {
        // MetaMask is locked or the user has not connected any accounts
        resetAccount();
      } else {
        getAccount();
      }
    };

    const onChainChanged = (chainId: string) => {
      if (account) {
        toast.success(
          'Network change has been detected. Please refresh your page'
        );
      }
      // Correctly handling chain changes can be complicated.
      // We recommend reloading the page unless you have good reason not to.
      // window.location.reload();
    };

    const onDisconnect = (error: IProviderRpcError) => {
      console.log(`on disconnect`);
      toast.success('on disconnect');
    };

    const onMessage = (msg: IProviderMessage) => {
      console.log(`on message : ${msg.type} - ${msg.data}`);
      toast.success(`on message: ${msg.type} - ${msg.data}`);
    };

    const onBlockChanged = (blockNumber: number) => {
      setBlockNumber(blockNumber);
    };

    if (provider) {
      ethereum.on('accountsChanged', onAccountChanged);
      ethereum.on('chainChanged', onChainChanged);
      ethereum.on('disconnect', onDisconnect);
      ethereum.on('message', onMessage);
      provider.on('block', onBlockChanged);

      try {
        getAccount();
      } catch (err: any) {
        toast.error(err);
      }
    }
 
    return () => {
      if (ethereum) {
        ethereum.removeListener('accountsChanged', onAccountChanged);
        ethereum.removeListener('chainChanged', onChainChanged);
        ethereum.removeListener('disconnect', onDisconnect);
        ethereum.removeListener('message', onMessage);
      }
      if (provider) {
        provider.removeListener('block', onBlockChanged);
      }
    };
  }, [provider]);

  const connect = async (): Promise<ConnectionStatus> => {
    if (ethereum && status === ConnectionStatus.DISCONNECTED) {
      setStatus(ConnectionStatus.CONNECTING);
      try {
        await ethereum.request({ method: 'eth_requestAccounts' });
        getAccount();
      } catch (e: any) {
        setStatus(ConnectionStatus.DISCONNECTED);
        if (e.code === -32002) {
          toast.error(`Metamask window opened`);
        } else {
          throw e;
        }
      }
    }
    return status;
  };

  const getAccount = async () => {
    if (!provider) throw new Error('Provider not setup');

    setStatus(ConnectionStatus.CONNECTING);
    const fetchedAccount = await fetchAccount(provider);
    // console.log('fetched account and now setting', fetchedAccount);
    if (fetchedAccount) {
      setAccount(fetchedAccount);
      setStatus(ConnectionStatus.CONNECTED);
    } else {
      resetAccount();
    }
  };

  const resetAccount = (): void => {
    setAccount(undefined);
    setStatus(ConnectionStatus.DISCONNECTED);
  };

  return (
    <EthereumContext.Provider
      value={{
        connect,
        account,
        status,
        blockNumber,
        getAccount,
        provider,
      }}
    >
      {children}
    </EthereumContext.Provider>
  );
};
export default EthereumProvider;
