// @ts-nocheck

import React from 'react';
import anime from 'animejs';
import {
  AnimatorRef,
  AnimatorClassSettings,
  withAnimator,
} from '@arwes/animation';
import {
  StyledAccentedCard,
  AccentedTitle,
  AccentedTitleContainer as TitleContainer,
  AccentedContentContainer as ContentContainer,
  Splatter,
} from './styled';

type AccentedCardProps = {
  children: React.ReactNode;
  Background: () => React.ReactNode;
};
export const AccentedCard = ({
  className,
  children,
  renderBackground,
  ...props
}: AccentedCardProps) => {
  return (
    <StyledAccentedCard className={className} {...props}>
      <>
        <ContentContainer></ContentContainer>
        {renderBackground()}
        <TitleContainer>{children}</TitleContainer>
        {/* <AnimatedCardTitle title={title} animator={{ activate: true }} /> */}
        <Splatter src='images/pink_splatter.png' />
      </>
    </StyledAccentedCard>
  );
};

const Title = (props: any) => {
  const ref = React.useRef(null);
  props.animator.setupAnimateRefs(ref);

  return <AccentedTitle ref={ref}>{props.title}</AccentedTitle>;
};

const duration = { enter: 250, exit: 250 };
const onAnimateEntering = (animator: AnimatorRef, ref: any): void => {
  anime({
    targets: ref.current,
    duration: animator.duration.enter,
    easing: 'linear',
    strokeDashoffset: [-300, 0],
  });
};
const onAnimateExiting = (animator: AnimatorRef, ref: any): void => {
  anime({
    targets: ref.current,
    duration: animator.duration.enter,
    easing: 'linear',
    strokeDashoffset: [0, -300],
  });
};
const animatorClassSettings: AnimatorClassSettings = {
  duration,
  onAnimateEntering,
  onAnimateExiting,
};

// @ts-ignore
export const AnimatedCardTitle = withAnimator(animatorClassSettings)(Title);
