import { ChevronUp, ChevronDown } from "@styled-icons/octicons";

import { TextButton } from "@components/Buttons";

import { StyledIncrementer, StyledInput, StyledSVGButton } from "./styled";
import { IncrementerProps } from "./types";

const Incrementer = ({ onChange, quantity, max, min }: IncrementerProps) => {
  const applyChange = (newValue: number) => {
    if (quantity !== newValue) {
      onChange(newValue);
    }
  };

  const onClickPlus = () => {
    const newValue = Math.min(quantity + 1, max);
    applyChange(newValue);
  };

  const onClickMinus = () => {
    const newValue = Math.max(quantity - 1, min);
    applyChange(newValue);
  };

  const onClickMax = () => {
    applyChange(max);
  };

  const handleChange = (e: any) => {
    const value = e.currentTarget.value;
    const newValue = Math.max(Math.min(value, max), min);
    applyChange(newValue);
  };

  return (
    <StyledIncrementer>
      {/*
      <TextButton disabled={max === 0 || quantity === max} onClick={onClickMax}>
        Max
      </TextButton>
      */}
      <StyledSVGButton disabled={quantity === max} onClick={onClickPlus}>
        <ChevronUp />
      </StyledSVGButton>
      <StyledInput value={quantity} onChange={handleChange} />
      <StyledSVGButton disabled={quantity === min} onClick={onClickMinus}>
        <ChevronDown />
      </StyledSVGButton>
    </StyledIncrementer>
  );
};

export default Incrementer;
