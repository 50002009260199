// @ts-nocheck

import React, { FC, useRef } from 'react';
import { TransitionGroup } from 'react-transition-group';
import anime from 'animejs';
import {
  AnimatorRef,
  AnimatorClassSettings,
  withAnimator,
} from '@arwes/animation';

import { AnimatedCard, CardContainer } from '@components/Cards';
import { AccentedHeader } from '@components/Typography';
import {
  VisionMapOverviewContainer,
  StyledCardContainer,
  CloseButton,
  HiddenQR,
} from './styled';
import { Close } from '@styled-icons/ionicons-outline';
import VisionmapDetail from './VisionmapDetail';
import VisionmapCard from './VisionmapCard';
import { DETAIL_MAP } from './constants';

const VISION_CARDS = Array.from(DETAIL_MAP.keys());

interface Props {
  className?: string;
  toggleDetail: (detail: any) => void;
  animator: AnimatorRef;
  activeCard: string;
}

const VisionmapOverview: FC<Props> = ({
  activeCard,
  animator,
  toggleDetail,
}) => {
  const ref = useRef(null);
  const cardRef = useRef(null);
  animator.setupAnimateRefs(ref);

  const handleClick = (event: Event) => {
    const detail = event.currentTarget.id;
    if (detail.length > 0) {
      toggleDetail(detail);
    }
  };

  return (
    <VisionMapOverviewContainer ref={ref}>
      <CardContainer direction='horizontal'>
        <AnimatedCard>
          <AccentedHeader className='lgInline'>
            VISION<span>MAP</span>
          </AccentedHeader>
          <span>CREATORS FIRST. EMPOWER COMMUNITY. PREVENT DYSTOPIA.</span>
          <HiddenQR src='images/other/qr-73-1.png' />
        </AnimatedCard>
      </CardContainer>

      <CardContainer direction='horizontal' className='spread'>
        <AnimatedCard>
          <TransitionGroup appear>
            <StyledCardContainer direction='horizontal' ref={cardRef}>
              <CloseButton onClick={handleClick} id={activeCard}>
                <Close />
              </CloseButton>
              <VisionmapDetail
                // @ts-ignore
                id={activeCard}
                card={cardRef}
              />
              {VISION_CARDS.map((id, index) => (
                <VisionmapCard
                  key={id}
                  id={id}
                  onClick={handleClick}
                  activeCard={activeCard}
                  index={index}
                />
              ))}
            </StyledCardContainer>
          </TransitionGroup>
        </AnimatedCard>
      </CardContainer>
      <CardContainer direction='horizontal' className='spread'>
        <div className='centered'>
          © 2022 NEON PLEXUS |{' '}
          <a href='mailto:team@neonplexus.io'>Team Contact</a>
        </div>
      </CardContainer>
    </VisionMapOverviewContainer>
  );
};

const duration = { enter: 0, exit: 0 };
const onAnimateEntering = (animator: AnimatorRef, ref: any): void => {
  anime({
    targets: ref.current,
    duration: animator.duration.enter,
    easing: 'linear',
    opacity: [0, 1],
  });
};
const onAnimateExiting = (animator: AnimatorRef, ref: any): void => {
  anime({
    targets: ref.current,
    duration: animator.duration.enter,
    easing: 'linear',
    opacity: [0, 1],
  });
};
const animatorClassSettings: AnimatorClassSettings = {
  duration,
  onAnimateEntering,
  onAnimateExiting,
};

// @ts-ignore
export default withAnimator(animatorClassSettings)(VisionmapOverview);
