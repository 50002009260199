import { CardContainer } from '@src/components/Containers';
import styled from 'styled-components';

export const ButtonCont = styled.div`
  position: fixed;
  display: flex;
  justify-content: left;
  align-items: left;
  flex-wrap: wrap;
  padding: ${({ theme }) => theme.spacing.md};
  max-width: 390px;
  left: 20px;
  bottom: 20px;
  /* background: red; */

  @media ${({ theme }) => theme.maxBreakpoints.md} {
    max-width: none;
    width: 100%;
    left: 0;
    bottom: 0;
  }

  & div {
    margin-bottom: 0px;
  }

  & p > mark {
    font-size: 1.4em;
    line-height: 2em;
  }

  & p {
    padding: 1em 1.75em 0px 1.75em;
  }

  & button {
    margin: 0 1em 1em 1em;
  }
`;

export const ButtonGenerate = styled.button`
  background-image: url('images/boombox/generate.png');
  background-color: transparent;
  border-style: none;
  width: 300px;
  height: 80px;

  &:active {
    &:not(:disabled) {
      transform: translateY(2px);
    }
  }
`;

export const ButtonPlay = styled.button`
  background-image: url('images/boombox/play.png');
  background-color: transparent;
  border-style: none;
  width: 300px;
  height: 80px;

  &:active {
    &:not(:disabled) {
      transform: translateY(2px);
    }
  }
`;

export const ButtonPause = styled.button`
  background-image: url('images/boombox/pause.png');
  background-color: transparent;
  border-style: none;
  width: 300px;
  height: 80px;

  &:active {
    &:not(:disabled) {
      transform: translateY(2px);
    }
  }
`;

export const UnityMobileWarning = styled.p`
  text-align: center;
  font-size: 1.2em;
  font-weight: 100;
  padding: 0;
  margin: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 10%,
    rgba(253, 187, 45, 0) 100%
  );
  width: 100%;
`;

export const BoomboxContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: -1;
`;

export const LoadingBarBlock = styled.div`
  display: block;
`;

export const ProgressBarFull = styled.div`
  display: block;
`;
