import React from 'react';
import ProgressBar from '@components/ProgressBar';
import { PlazaLink } from '@src/components/Links/index';
import Gtag from '@src/components/Gtag/Gtag';
import { RoadmapAmountSpan } from '../styled';

const Metacity = ({ title, subtitle }: any) => (
  <>
    <Gtag pretty_title='META CITY: NEON PLEXUS' page_title='visionmap_metacity' page_path='/visionmap/metacity' />
    <p>
      OVERRIDE is just the genesis collection. Holders of the OVERRIDE
      collection will become the VIPs of META CITY.
    </p>
    <p>
      <ProgressBar progress={10}>Buffering...</ProgressBar>
      <RoadmapAmountSpan>Unlocked when 50% of the collection has minted</RoadmapAmountSpan>
      <ul>
        <li>The Lore</li>
        <li>Concept Art</li>
        <li>Community Tools</li>
      </ul>
    </p>
    <p>
      <ProgressBar progress={1}>Awaiting_Handshake...</ProgressBar>
      <RoadmapAmountSpan>Unlocked when 100% of the collection has minted</RoadmapAmountSpan>
      <ul>
        <li>Land</li>
        <li>Factions</li>
        <li>GameFi</li>
        <ul>
          <li>Staking</li>
          <li>
            <PlazaLink
              href='/api/uuid/b84ab2e8-092d-4575-b3b3-d7247abbc294'
              target='_blank'
            >
              Tokens
            </PlazaLink>
          </li>
        </ul>
        <li>and more…</li>
      </ul>
    </p>
  </>
);

export default Metacity;
