import { HTMLProps } from 'react';

export interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  onClick: (event: any) => any;
  text?: string;
  children?: React.ReactNode;
  buttonType?: Type;
  disabled?: boolean;
}

export enum Type {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  LINK = 'link',
  ICON = 'icon',
  MINIMAL = 'minimal',
}
