import styled from 'styled-components';
import { ThreeBars } from '@styled-icons/octicons';
import { IconLink } from '@components/Links';

import NavItem from './NavItem';
import Logo from './Logo';
import { NavWrapperProps } from './types';

export const StyledNavItem = styled(NavItem)`
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.lg};
  text-transform: uppercase;
  white-space: nowrap;
  position: relative;
`;

export const HamburgerIcon = styled(ThreeBars)`
  height: 20px;
  width: 20px;
`;

export const LinksContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledLink = styled(IconLink)`
  margin: 5px 10px;
`;

export const StyledLogo = styled(Logo)`
  justify-content: left;
  border-right: 0;
  padding: 0;
  flex: 1;
`;

export const StyledRaritySniper = styled.div`
  background: url('images/rarity-sniper.png');
  width: 25px;
  height: 25px;
`;


export const SoonBanner = styled.div`
  position: absolute;
  background: transparent;
  font-family: 'Jost', sans-serif;
  font-size: 0.5em;
  font-weight: bold;
  top: 0;
  left: 0;
  padding: 0px 5px;
  color: ${({ theme }) => theme.colors.accentSecondary};
  margin: -3px 0;
  z-index: -1;
`;

const mobileOpenedStyles = `
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 23px auto; 
  `;

export const NavWrapper = styled.div<NavWrapperProps>`
  background: ${({ theme }) => theme.colors.black};
  padding: 0px;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.md};
  top: 0;
  font-family: 'Jost', sans-serif;
  z-index: 1000;
  position: fixed;
  width: calc(100vw - (100vw - 100%));

  @media ${({ theme }) => theme.breakpoints.md} {
    background: ${({ theme, isHome }) =>
      isHome ? theme.colors.transparent : theme.colors.navBg};
  }

  & button {
    margin: 0px 3px 0px 3px;
  }

  ${HamburgerIcon} {
    display: none;
  }

  @media ${({ theme }) => theme.maxBreakpoints.md} {
    ${({ isOpen }) => isOpen && mobileOpenedStyles};
    padding: 10px 20px;

    ${LinksContainer} {
      display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      flex: 1;
      grid-column: span 2;
      height: 100%;

      ${StyledNavItem}, ${StyledLink} {
        margin: 20px 0;
        padding: 0;
      }

      ${StyledLink} {
        width: 100%;
        display: flex;
        justify-content: space-between;
        &:before {
          content: attr(title);
          text-transform: uppercase;
        }
      }
    }

    ${HamburgerIcon} {
      display: block;
      justify-self: flex-end;
    }
  }
`;
