import styled from 'styled-components';
import { Card } from '@components/Cards';

export const PlexusCard = styled(Card)`
  position: relative;
  width: 100%;
  overflow: hidden;
  background: rgb(255, 0, 180, 0.25);
  padding: 4px 8px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding: 8px 16px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 16px 32px;
  }
`;
