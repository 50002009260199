import React, { FC, useRef } from 'react';
import { AnimatorRef } from '@arwes/animation';

import { CardContainer, Card } from '@components/Cards';

import { VisionmapContent, OverlayedSVG, StyledPath } from './styled';
import { DETAIL_MAP } from './constants';

interface Props {
  className?: string;
  animator: AnimatorRef;
  open: boolean;
  id: string;
  card?: object;
}

const VisionmapDetail: FC<Props> = ({
  open,
  animator,
  className,
  id,
  card,
}) => {
  // @ts-ignore
  const detailConfig = DETAIL_MAP.get(id);

  return detailConfig && detailConfig.content ? (
    // @ts-ignore
    <VisionmapContent active={open} card={card}>
      <OverlayedSVG
        viewBox='0 0 100 100'
        preserveAspectRatio='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <StyledPath
          d='M0,60 L0,70 L1.25,72.5 L1.25,100 L10,100'
          strokeDasharray='250px'
          strokeDashoffset='0'
        />
        <StyledPath
          d='M0,60 L0,70 L1.25,72.5 L1.25,100 L12.5,100'
          strokeDasharray='20px'
          strokeDashoffset='5.5px'
        />
        <StyledPath
          d='M0,5 L0,0 L5,0'
          strokeDasharray='200px'
          strokeDashoffset='0px'
        />
        <StyledPath
          d='M0,10 L0,0 L5,0'
          strokeDasharray='10px'
          strokeDashoffset='7.5px'
        />
      </OverlayedSVG>
      <CardContainer direction='horizontal'>
        <Card className={className}>
          <>
            <h2>{detailConfig.title}</h2>
            <p>
              <mark>{detailConfig.subtitle}</mark>
            </p>
            <detailConfig.content
              title={detailConfig.title}
              subtitle={detailConfig.subtitle}
            />
          </>
        </Card>
      </CardContainer>
    </VisionmapContent>
  ) : null;
};

export default VisionmapDetail;
