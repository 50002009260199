import styled from 'styled-components';
import { glitchIn, glitchOut, slideIn, slideOut } from '@assets/Keyframes';
import { createClipPath } from '@assets/utils';
import { CardContainer, AccentedCard } from '@components/Cards';

export const HiddenQR = styled.img`
  position: absolute;
  height: 50px;
  width: 50px;
  top: 0;
  right: 0;
  /* background: white; */
  margin: 1px 1px 0 0;
`;

export const RoadmapAmountSpan = styled.div`
  width: 100%;
  text-align: center;
  color: grey;
`;

export const VisionMapContainer = styled.div`
  position: relative;
`;

export const VisionMapOverviewContainer = styled.div`
  width: 100%;
  z-index: 1;
  opacity: 0;
`;

export const VisionmapContent = styled.div<{ active: any }>`
  background: rgb(15, 15, 25, 0.75);
  transition: width 2000ms ease-in;
  transition-delay: 1000s;
  grid-column: span 3;
  grid-row: 1;
  margin-bottom: 7px;
  position: absolute;

  @media ${({ theme }) => theme.breakpoints.xl} {
    padding-right ${({ theme: { spacing } }) => spacing.xxl};
    padding-left ${({ theme: { spacing } }) => spacing.xl};

    h2 {
      transform: rotate(-5deg);
      position: relative;
      top: -25px;
      left: -50px;
    }
  }

  ${
    //@ts-ignore
    ({ card, theme }) =>
      card.current
        ? `
    width: ${card.current.offsetWidth}px;

    @media ${theme.breakpoints.xl} {
      width: ${card.current.offsetWidth * 0.7}px;
      height: ${card.current.offsetHeight}px;
    }`
        : ''
  }
`;

export const OverlayedSVG = styled.svg`
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;

  @media ${({ theme }) => theme.breakpoints.xl} {
    margin: 0 -32px;
  }
`;

export const StyledPath = styled.path`
  stroke-dasharray: ${(props) => props.strokeDasharray};
  stroke-dashoffset: ${(props) => props.strokeDashoffset};
  fill: none;
  stroke: ${({ theme }) => theme.colors.accent};
  stroke-width: 2;
  vector-effect: non-scaling-stroke;
`;

export const StyledCard = styled(AccentedCard)`
  width: 100%;
  position: relative;
  left: 0;
  transition: left 500ms, clip-path 500ms;

  h2::first-letter {
    font-size: 1.25em;
    line-height: 1em;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    height: calc(100vh - 340px);
    min-height: 550px;
    max-height: 550px;
  }

  ${createClipPath()}

  ${
    //@ts-ignore
    ({ cardWidth, index, expanded, theme }) =>
      expanded
        ? `
      display: none;

      @media ${theme.breakpoints.xl} {
        display: block;
        left: ${(cardWidth + 32) * (3 - index)}px;
        opacity: 1;
        clip-path: polygon(
          -47.5% 0%,
          87.5% 0%,
          100% 7.5%,
          100% 72.5%,
          97% 75%,
          97% 100%,
          33% 100%,
          30% 98.5%,
          -10% 98.5%,
          -12.5% 100%,
          -55% 100%,
          -47.5% 40%,
          -52.5% 37.5%);

        video {
          left: -70%;
        }

        div, img {
          display: none;
        }
      }
        `
        : ''
  }
`;

export const STAGGER = 250;
export const duration = { enter: 250, exit: 250 };

export const AnimatedCard = styled.div`
  animation-fill-mode: forwards;
  pointer-events: ${
    //@ts-ignore
    ({ expanded }) => (expanded ? 'none' : 'all')
  };

  &.enter,
  &.enter-done,
  &.appear-done {
    animation-name: ${slideIn}, ${glitchIn};
    animation-timing-function: ease-in-out;
    animation-duration: ${({ duration }: any) => duration.enter}ms;
    display: flex;
  }

  &.appear {
    transform: translateX(-50px);
    opacity: 0;
  }

  &.exit,
  &.exit-active,
  &.exit-done {
    display: block;
    animation-name: ${slideOut}, ${glitchOut};
    animation-duration: ${({ duration }: any) => duration.exit}ms;
    pointer-events: none;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const StyledVideo = styled.video`
  object-fit: cover;
  position: relative;
  overflow: hidden;
  transition: left 500ms;
  max-height: inherit;
  width: 175%;
  left: -20%;

  @media ${({ theme }) => theme.breakpoints.md} {
    transition: left 500ms;
    max-height: inherit;
    width: 200%;
    left: -40%;
    top: 0%;
  }
`;

export const StyledCardContainer = styled(CardContainer)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: ${({ theme: { spacing } }) => spacing.lg};
  margin: 16px 0px;

  @media ${({ theme }) => theme.breakpoints.md} {
    gap: ${({ theme: { spacing } }) => spacing.xl};
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: 1fr;
  }
`;

export const HeroContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  opacity: 0.25;
`;

export const CloseButton = styled.div`
  pointer-events: all;
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacing.md};
  position: absolute;
  font-size: 3em;
  top: -0.25em;
  right: 0;
  height: 0.75em;
  width: 0.75em;
  display: none;
  z-index: 1;

  ${
    //@ts-ignore
    ({ id }) => (id ? 'display: block;' : '')
  }
`;
