import styled from 'styled-components';
import { createClipPath } from '@assets/utils';
import { SpacedContainer } from '@components/Containers';

export const TeamMembers = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  > div {
    width: 20%;

    img {
      width: 100%;
      ${createClipPath()}
    }
  }
`;

export const TeamMemberContainer = styled.div`
  position: relative;
`;

export const TeamMemberBanner = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.accent};
  font-family: 'NeonOverdrive', sans-serif;
  font-size: 0.75em;
  font-weight: 100;
  left: 0;
  bottom: 0;
  padding: 1px 1px;
  color: #fff;
  margin: -5px;

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 1px 5px;
    font-size: 1em;
    bottom: 10%;
  }
`;

export const TeamSpacedContainer = styled(SpacedContainer)`
  font-size: 0.75em;

  svg {
    display: none;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 0 10px;
    font-size: 1em;
    svg {
      display: block;
    }
  }
`;
