import styled from 'styled-components';

import { createClipPath } from '@assets/utils';
import { AnimatedCard, CardContainer } from '@components/Cards';

export const AnimatedIntroCard = styled(AnimatedCard)`
  background: red;
`;

export const FadedImg = styled.div`
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    ),
    url('${({ src }: { src: string }) => src}');
  background-position: 0% 0%;
  background-size: 100%;
  height: 75px;
  object-fit: cover;
  object-position: center;
  width: 125%;
  margin: -16px;

  @media ${({ theme }) => theme.breakpoints.md} {
    background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0) 25%,
        rgba(0, 0, 0, 1)
      ),
      url('${({ src }: { src: string }) => src}');
    background-position: initial;
    background-size: auto;
    width: 30%;
    height: 250px;
    background-size: cover;
    margin: -15px 15px -15px -15px;
    position: relative;
    left: -16px;
    mix-blend-mode: screen;
  }
`;

export const FadedImgBottom = styled.div`
  background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0) 25%,
      rgba(0, 0, 0, 1)
    ),
    url('${({ src }: { src: string }) => src}');
  background-position: 100% 100%;
  background-size: 100%;
  height: 150px;
  object-fit: cover;
  object-position: center;
  width: 125%;
  margin: -16px;

  @media ${({ theme }) => theme.breakpoints.md} {
    display: none;
`;

export const TinyTitle = styled.h2`
  position: absolute;
  font-size: 1em;
  transform: rotate(-10deg);
  top: 2.5px;
  left: 2.5px;
  display: none;

  @media ${({ theme }) => theme.breakpoints.md} {
    font-size: 1.5em;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    display: block;
    top: 15px;
    left: 15px;
  }
`;

export const StyledVideoContainer = styled.div`
  max-height: 425px;
  ${createClipPath()}

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 30%;
    max-height: 475px;
    margin: -15px -32px;
    margin-left: 0;
    clip-path: polygon(
      -25% 0%,
      100% 0%,
      100% 100%,
      10% 100%,
      // Larger broken piece starts here
      -0.35% 60%,
      -7.5% 57.5%,
      -10.5% 47.25%,
      -4.65% 49%,
      -2% 59.43%,
      -0.35% 60%,
      // Larger broken piece ends here
      // Smaller broken piece starts here
      -3.55% 48.25%,
      -10.75% 46%,
      -11.85% 42.5%,
      -5.75% 44.5%,
      -5% 47.8%,
      -3.55% 48.25%,
      // Smaller broken piece ends here
      -5% 42.5%,
      -12.5% 40%
    );
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    max-height: 450px;
  }
`;

export const StyledVideo = styled.video`
  object-fit: cover;
  position: relative;
  overflow: hidden;
  transition: left 500ms;
  max-height: inherit;
  object-position: center;
  width: 100%;

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 300%;
    left: -50%;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    width: 175%;
    left: -25%;
  }
`;

export const SneakPeeks = styled.div`
  display: flex;
  justify-content: space-between;
  width: 105%;
  margin: 16px 0px -32px -32px;

  div {
    width: 20%;
    img {
      width: 100%;
    }
  }
`;

export const HeroContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  opacity: 0.25;
  z-index: -1;
`;

export const PlazaCode = styled.span`
  height: 0;
  display: inherit;
  color: transparent;
  position: absolute;
`;
