import React, { useContext } from 'react';
import { Discord, Twitter } from '@styled-icons/simple-icons';
import { useLocation, useNavigate } from 'react-router-dom';

import { OpenSea } from '@assets/Logos';
import { ConfigContext } from '@src/ConfigContext';

import {
  StyledLink,
  StyledLogo,
  SoonBanner,
  StyledNavItem,
  LinksContainer,
  HamburgerIcon,
  NavWrapper,
  StyledRaritySniper,
} from './styled';
import { Props } from './types';

const NavBar = ({ className, ...props }: Props) => {
  const { pathname } = useLocation();
  const { pageWhitelist, pageMint, pageDebug } = useContext(ConfigContext);
  const [hamburgerOpened, setHamburger] = React.useState(false);

  const toggleHamburger = () => setHamburger(!hamburgerOpened);

  const closeHamburger = () => setHamburger(false);

  return (
    <NavWrapper
      className={className}
      isOpen={hamburgerOpened}
      isHome={pathname === '/' || pathname === '/boombox' || pathname === '/mint' || pathname === '/whitelist'}
    >
      <StyledLogo />
      <HamburgerIcon onClick={toggleHamburger} />
      <LinksContainer>
        <StyledNavItem url='/' internal onClick={closeHamburger}>
          Mint
        </StyledNavItem>
        <StyledNavItem url='/about' internal onClick={closeHamburger}>
          About
        </StyledNavItem>
        <StyledNavItem url='/visionmap' internal onClick={closeHamburger}>
          Visionmap
        </StyledNavItem>
        <StyledNavItem url='/boombox' internal onClick={closeHamburger}>
          Boombox
        </StyledNavItem>
        <StyledLink href='https://neonplexus.io/discord?utm_source=neonplexusio_navbar&utm_medium=website&utm_campaign=website&utm_id=04F287465644' title='Discord'>
          <Discord />
        </StyledLink>
        <StyledLink href='https://twitter.com/neonplexus' title='Twitter'>
          <Twitter />
        </StyledLink>
        <StyledLink
          href='https://opensea.io/collection/neonplexusoverride'
          title='OpenSea'
        >
          <OpenSea />
        </StyledLink>
      </LinksContainer>
    </NavWrapper>
  );
};

export default NavBar;
