import styled from 'styled-components';

export const StyledPath = styled.path`
  stroke-dasharray: 1200px;
  stroke-dashoffset: 0;
  fill: none;
  stroke: ${({ theme }) => theme.colors.secondaryMuted};
  stroke-width: 1;
  vector-effect: non-scaling-stroke;
`;

export const OverlayedSVG = styled.svg`
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
`;

export const AnimatedCardContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  /* Corner Borders */
  --b: 2px; /* thickness of the border */
  --c: ${({ theme }) => theme.colors.secondaryMuted}; /* color of the border */
  --w: 25px; /* width of border */

  border: var(--b) solid transparent; /* space for the border */
  --g: #0000 90deg, var(--c) 0;
  background: conic-gradient(from 90deg at top var(--b) left var(--b), var(--g))
      0 0,
    conic-gradient(from 180deg at top var(--b) right var(--b), var(--g)) 100% 0,
    conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--g)) 0 100%,
    conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--g)) 100%
      100%;
  background-size: var(--w) var(--w);
  background-origin: border-box;
  background-repeat: no-repeat;
`;

export const StyledAccentedCard = styled.div`
  position: relative;
  display: block;

  video {
    transition: transform 1s;
  }

  &:hover {
    transform: perspective(500px) translateZ(15px);

    video {
      transform: perspective(500px) translateZ(15px);
    }
  }
`;

export const AccentedBackgroundContainer = styled.div`
  height: 100%;
`;

export const Splatter = styled.img`
  position: absolute;
  bottom: -15%;
  left: -50%;
  opacity: 0.75;
  width: 150%;

  @media ${({ theme }) => theme.breakpoints.lg} {
    width: 115%;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    width: 150%;
  }
`;

export const AccentedTitleContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30%;
  max-height: 135px;
  text-align: center;
  padding: 7.5px 5px;
  z-index: 225;

  h2.tinyTitle {
    position: absolute;
    font-size: 1em;
    transform: rotate(-10deg);
    background: #f00;
    width: 0;

    @media ${({ theme }) => theme.breakpoints.md} {
      font-size: 1.5em;
    }

    @media ${({ theme }) => theme.breakpoints.lg} {
      top: -5px;
    }
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    height: 25%;
  }
`;

export const AccentedContentContainer = styled.div`
  background: ${({ theme: { colors } }) => colors.accentMuted};
  padding: ${({ theme: { spacing } }) => spacing.md};
  width: 100%;
  height: 25%;
  text-align: center;
  padding: 7.5px 5px;
  position: absolute;
  bottom: 0;
  clip-path: polygon(0 0, 100% 22.5%, 100% 100%, 0% 100%);
  z-index: 200;

  @media ${({ theme }) => theme.breakpoints.md} {
    height: 25%;
    max-height: 135px;
  }

  legend {
    padding: 0px 20px;
  }
`;

export const AccentedTitle = styled.div`
  background: ${({ theme: { colors } }) => colors.accent};
  position: absolute;
  top: 10%;
  left: -10px;
  padding: ${({ theme: { spacing } }) => spacing.md};
  box-shadow: 3px 5px 7px 1px black;
  text-transform: uppercase;
  max-width: 80%;

  font-family: 'NeonOverdrive', sans-serif;
  font-weight: 100;
`;
