const baseColors = {
  white: '#f0f0f0',
  pink: '#ff00b4',
  aqua: '#00e3fe',
  yellow: '#f5ecd4',
  red: 'red',
  green: 'green',
  transparent: 'transparent',
  black: '#07040d',

  pink10: '#d22bc8',

  purple20: `#cabfd8`,

  gray10: '#E7EAEB',
  gray20: '#C1C3CC',
  gray25: '#A5A5A5',
  gray30: '#AEAEBC',
  gray40: '#9E9BAB',
  gray50: '#90889b',
  gray60: '#807687',
  gray70: '#6F6473',
  gray80: '#5D525E',
  gray90: '#4A4049',
  gray100: '#352E33',

  blue10: '#B1D7FC',
  blue20: '#89C4FA',
  blue30: '#62B0F8',
  blue40: '#3B9CF7',
  blue50: '#1387f5',
  blue60: '#0974D7',
  blue70: '#075FB0',
  blue80: '#064A89',
  blue90: '#043562',
};

const themeColors = {
  ...baseColors,
  primary: baseColors.pink,
  primaryActive: baseColors.blue60,
  primaryDisabled: baseColors.blue10,
  secondary: baseColors.white,
  secondaryMuted: baseColors.gray25,
  accent: baseColors.pink,
  accentMuted: baseColors.pink10,
  accentSecondary: baseColors.aqua,

  bgColor: baseColors.black,
  secondaryBgColor: baseColors.pink,

  bodyText: baseColors.white,

  success: baseColors.green,
  danger: baseColors.red,
  disabled: baseColors.gray50,
  diabledBg: baseColors.gray20,
};

const colors = {
  ...themeColors,
  navBg: baseColors.black,
};

export default colors;
