export const createClipPath = () => `
  clip-path: polygon(
    0% 0%,
    87.5% 0%,
    100% 7.5%,
    100% 72.5%,
    97% 75%,
    97% 100%,
    33% 100%,
    30% 98.5%,
    0% 98.5%,
    0% 100%,
    0% 100%,
    0% 40%,
    0% 37.5%);
`;

export const createClipPathV2 = () => `
  clip-path: polygon(0% 0%, 80% 0, 100% 30%, 100% 100%, 0% 100%);
`;

