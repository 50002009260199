import styled from 'styled-components';
import defaultTheme from '@theme/theme';
import { ButtonProps, Type } from './types';

export const StyledButtonText = styled.span`
  font-size: 1rem;
`;

export const StyledButton = styled.button<ButtonProps>(
  ({ buttonType: type = Type.PRIMARY, theme = defaultTheme }) => ({
    backgroundColor: 'transparent',
    color: 'inherit',
    textDecoration: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    padding: `${theme.spacing.sm} ${theme.spacing.md}`,
    borderRadius: theme.radii.none,
    '&:hover, &:active, &:focus': {
      color: theme.colors.primary,
      svg: {
        fill: theme.colors.primary,
      },
    },
    ...getKindStyle(type, theme),
  })
);

function getKindStyle(type: Type, theme: any) {
  switch (type) {
    case Type.PRIMARY:
      return {
        border: `1px solid ${theme.colors.primary}`,
        backgroundColor: theme.colors.primary,
        color: theme.colors.white,
        '&:hover, &:active': {
          '&:not(:disabled)': {
            borderColor: theme.colors.primaryActive,
            color: theme.colors.white,
            backgroundColor: theme.colors.primaryActive,
          },
        },
        '&:focus': {
          color: theme.colors.white,
        },
        '&:disabled, &:disabled:hover, &:disabled:active': {
          opacity: 0.5,
          cursor: 'default',
        },
      };
    case Type.LINK:
      return {
        backgroundColor: theme.colors.transparent,
        padding: 0,
        border: 'none',
        color: theme.colors.primary,
        '&:hover': {
          textDecoration: 'underline',
        },
        '&:active': {
          backgroundColor: theme.colors.transparent,
          color: theme.colors.primary,
          textDecoration: 'underline',
        },
      };
    case Type.MINIMAL:
      return {
        backgroundColor: theme.colors.transparent,
        border: 'none',
        boxShadow: 'none',
        padding: 0,
      };
    default:
      return {
        padding: `${theme.spacing.xs} ${theme.spacing.md}`,
      };
  }
}
