import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { BACKEND_URI } from "./Env";

export interface IConfigContext {
    pageWhitelist:boolean;
    pageMint:boolean;
    pageDebug:boolean;
}
  
export const ConfigContext = createContext<IConfigContext>({
    pageWhitelist: true,
    pageMint: false,
    pageDebug: false
});

const ConfigProvider = ({ children }: any) => {

    const [pageWhitelist, setPageWhitelist] = useState(true);
    const [pageMint, setPageMint] = useState(false);
    const [pageDebug, setPageDebug] = useState(false);
  
    useEffect(() => {
        const fetchConfig = async () => {
            await axios.get(BACKEND_URI + '/api/config').then(
                (response) => {
                  const {
                    success,
                    pageMint,
                    pageDebug
                  } = response.data as any;
                  if (success) {
                    setPageMint(pageMint);
                    setPageDebug(pageDebug);
                    setPageWhitelist(pageWhitelist);
                  }
                }
              ).catch((err) => {
                  console.error(err);
              });
        }
        fetchConfig();
    });
  
    return (
      <ConfigContext.Provider
        value={{
          pageWhitelist: pageWhitelist,
          pageMint: pageMint,
          pageDebug: pageDebug
        }}
      >
        {children}
      </ConfigContext.Provider>
    );
};

export default ConfigProvider;